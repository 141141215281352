import * as React from 'react';
import styled, { css } from 'styled-components';
import { Button, ButtonStyledStyles, ButtonStyles } from '../form/Button';
import BaseModal, { Footer } from './BaseModal';
import { rem } from 'polished';

const ModalButtonStyles = css`
  flex: 1 0 100%;
  ${({ theme }) => theme.media(600)} {
    flex: 0 0 auto;
  }
`;

export const Button1 = styled(Button)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  ${ModalButtonStyles};
  margin-top: ${rem(10)};
  ${({ theme }) => theme.media(600)} {
    margin-top: 0;
    order: -1;
  }
`;

export const Button2 = styled(Button)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  ${ModalButtonStyles};
`;

export const StyledFooter = styled(Footer)<{ buttonPositionDesktop: string }>`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  ${({ theme }) => theme.media(600)} {
    justify-content: ${({ buttonPositionDesktop }) => buttonPositionDesktop};
  }
`;

interface ModalProps {
  title?: string;
  bodyText?: any;
  buttonText?: string;
  onButtonClick?: (event) => void;
  onCloseButtonClick?: (event) => void;
  loading?: boolean;
  buttonPositionDesktop?: string;
  titleFontWeight?: string;
}

class ConfirmModal extends React.PureComponent<ModalProps> {
  public render() {
    const {
      title,
      bodyText,
      buttonText,
      loading,
      buttonPositionDesktop = 'center',
      titleFontWeight = 'normal',
    } = this.props;

    return (
      <BaseModal
        title={title}
        body={bodyText}
        loading={loading}
        titleFontWeight={titleFontWeight}
        footer={
          <StyledFooter buttonPositionDesktop={buttonPositionDesktop}>
            <Button1 className="primary lg" click={this.props.onButtonClick}>
              {buttonText}
            </Button1>
          </StyledFooter>
        }
        onCloseButtonClick={this.props.onCloseButtonClick}
      />
    );
  }
}

export default ConfirmModal;
