import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { __ } from 'react-i18n';
import { loadShopNetworkMenu, loadShopDetail } from './actions';
import { ContentWithSidebar } from '../../theme/libraries/grid';
import { getBaseUrl } from '../../utilities';
import StoreDetail from '../../components/_layout/Stores/StoreDetail';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import { rem } from 'polished';
import { Loader } from '../../components/_helpers/Loader/Loader';
import ContentContainerWithSidebar from '../../components/_layout/Page/ContentContainerWithSidebar';
import SidebarContainer from '../../components/_layout/Sidebar/Sidebar';
import { setCurrentUniqs } from '../App/actions';

const ShopNotFound = styled.div`
  font-size: ${rem(25)}
  text-align: center;
`;

const LoaderWrapper = styled.div`
  margin-top: ${rem(50)};
`;

export interface ShopDetailProps {
  shopNetworkMenu: any;
  shopDetail: any;
  dispatch: any;
}

class ShopDetail extends React.Component<ShopDetailProps> {
  public static async getInitialProps(props) {
    await props.dispatch(loadShopNetworkMenu());
    await props.dispatch(loadShopDetail(props.webShopId));

    return props;
  }

  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    this.props.dispatch(setCurrentUniqs(['SHOP_NETWORK']));
  }

  public componentWillUnmount() {
    this.props.dispatch(setCurrentUniqs([]));
  }

  public onBackClick = () => {
    this.props.dispatch(push(getBaseUrl('/predajne')));
  };

  public render() {
    const { shopNetworkMenu, shopDetail } = this.props;

    const sidebarItems =
      shopNetworkMenu?.length &&
      shopNetworkMenu.map((item) => {
        return {
          active: item.uniqid === 'SHOP_LIST',
          url: item.url,
          text: item.name,
        };
      });

    return (
      <ContentContainerWithSidebar>
        {shopNetworkMenu && (
          <React.Fragment>
            <SidebarContainer items={sidebarItems} />
            <ContentWithSidebar>
              {shopDetail ? (
                <StoreDetail
                  detail={shopDetail}
                  onClickBack={this.onBackClick}
                />
              ) : (
                <React.Fragment>
                  {shopDetail === undefined && (
                    <LoaderWrapper>
                      <Loader loading={true} />
                    </LoaderWrapper>
                  )}
                  {shopDetail === null && (
                    <ShopNotFound> {__('PREDAJŇA NEEXISTUJE')}</ShopNotFound>
                  )}
                </React.Fragment>
              )}
            </ContentWithSidebar>
          </React.Fragment>
        )}
      </ContentContainerWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shopNetworkMenu: state.shopDetail.shopNetworkMenu,
    shopDetail: state.shopDetail.shopDetail,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ShopDetail' })(ShopDetail),
);
