import * as React from 'react';
import styled from 'styled-components';
import { Link, WithRouterProps } from 'react-router';
import { rem, rgba } from 'polished';
import { getImagePath } from '../../../utilities/product';
import { __ } from 'react-i18n/lib';
import {
  capitalizeFirstLetter,
  debounce,
  getUrl,
  prop,
} from '../../../utilities';
import { connect } from 'react-redux';
import {
  setCategoryChanged,
  setCurrentCategoryGroup,
  setCurrentCategoryGroupId,
  setCurrentCategoryId,
} from '../../../containers/App/actions';
import API, { ThenArg } from '../../../services/API';
import {
  ANCHOR_UNIVERSAL_SETS,
  TITLE_ACCOUNT_SETS,
  URL_SETS,
  UrlMapTypes,
} from '../../../constants';
import { ChematCustomerType } from '../../../library/ChematCustomerType';

interface NavigationProps {
  dispatch: any;
  categoriesGroup: any[];
  currentPath?: string;
  urlMapData?: ThenArg<typeof API.getUrlMap> | null;
  currentCategoryGroup: any;
  currentCategoryGroupId?: number;
  user?: any;
}

function CustomCategories({
  dispatch = null,
  categoriesGroup = [],
  currentPath = '',
  urlMapData = null,
  currentCategoryGroupId = -1,
  user = null,
}: NavigationProps & WithRouterProps) {
  const [mouseOver, setMouseOver] = React.useState(null);
  const [hover, setHover] = React.useState<number>(0);
  const [onMouseEnterDebounce, onMouseEnterDebounceCancel] = debounce(
    onMouseEnter,
    200,
  );

  React.useEffect(() => {
    const categoryFounded = getCurrentCategory();
    if (categoryFounded) {
      dispatch(setCurrentCategoryGroupId(categoryFounded.id));
      dispatch(setCurrentCategoryGroup(categoryFounded));
    }
  }, []);

  React.useEffect(() => {
    if (!currentCategoryGroupId || currentCategoryGroupId === -1) {
      const categoryFounded = getCurrentCategory();
      if (categoryFounded) {
        dispatch(setCurrentCategoryGroupId(categoryFounded.id));
        dispatch(setCurrentCategoryGroup(categoryFounded));
      }
    }
  }, [window.location?.href]);

  function onMouseEnter(id) {
    setHover(id);
    setMouseOver(id);
  }

  function onMouseLeave() {
    onMouseEnterDebounceCancel();
    setHover(0);
    setMouseOver(null);
  }

  function onClickLink(category, id) {
    setHover(0);
    if (dispatch) {
      dispatch(setCategoryChanged(true));
      dispatch(setCurrentCategoryGroupId(category.id));
      dispatch(setCurrentCategoryGroup(category));
      dispatch(setCurrentCategoryId(id));
    }
  }

  function getCurrentCategory() {
    if (
      window &&
      window.location &&
      window.location.href &&
      window.location.pathname === '/3d'
    ) {
      return categoriesGroup.find((cg) => cg.uniqid === 'FURNITURE_SCHOOL');
    }

    return (
      categoriesGroup.find((category) =>
        currentPath.includes(category.uniqid_url),
      ) ||
      categoriesGroup.find((category) =>
        category.categories.find(
          (c) => currentPath && currentPath.includes(c.url),
        ),
      )
    );
  }

  return (
    <CCList>
      <CCItem
        key={-1}
        className={hover === -1 ? 'hover' : ''}
        onMouseEnter={onMouseEnterDebounce.bind(null, -1)}
        onMouseLeave={onMouseLeave.bind(null)}
      >
        <CCLink
          to={URL_SETS}
          className={
            currentPath === URL_SETS || urlMapData?.type === UrlMapTypes.school
              ? 'current'
              : ''
          }
          onClick={onMouseLeave.bind(null)}
        >
          <CCLinkText>{TITLE_ACCOUNT_SETS}</CCLinkText>
        </CCLink>
        {hover === -1 ? (
          <SubList>
            <SubItemSkvelkoWrapper>
              <SubItemSkvelko>
                <SubItemSkvelkoImg
                  to={URL_SETS}
                  onClick={onMouseLeave.bind(null)}
                >
                  <Image src="/images/skvelko_mapa.png" />
                </SubItemSkvelkoImg>
              </SubItemSkvelko>
              <SubItemSkvelko>
                <SubItemSkvelkoTop>
                  Skvelko je miesto, kde nakúpite školské potreby, ktoré{' '}
                  <b>odporučil triedny učiteľ</b> pre vášho školáka. Nájdete tu
                  presné zloženie sady školských potrieb, na blížiaci sa{' '}
                  <b>
                    školský rok {new Date().getFullYear()}/
                    {new Date().getFullYear() + 1}
                  </b>
                  . Všetko v jednej sade, na jednom mieste, objednané na pár
                  klikov a doručené až k vám domov.
                </SubItemSkvelkoTop>
                <SubItemSkvelkoBottomTitle>
                  {__('Škola nie je v zozname?')}
                </SubItemSkvelkoBottomTitle>
                <SubItemSkvelkoBottomBody>
                  Škola nie je v zozname? Odporúčame Vám zakúpiť{' '}
                  <SubItemSkvelkoLink
                    to={`${URL_SETS}#${ANCHOR_UNIVERSAL_SETS}`}
                    onClick={onMouseLeave.bind(null)}
                  >
                    univerzálnu sadu
                  </SubItemSkvelkoLink>{' '}
                  podľa ročníka žiaka. Ak si želáte doplniť školu do zoznamu -
                  vyplňte prosím{' '}
                  <SubItemSkvelkoLink
                    to={'/formular-pre-nezaradene-skoly'}
                    onClick={onMouseLeave.bind(null)}
                  >
                    formulár
                  </SubItemSkvelkoLink>
                  .
                </SubItemSkvelkoBottomBody>
              </SubItemSkvelko>
            </SubItemSkvelkoWrapper>
          </SubList>
        ) : (
          ''
        )}
      </CCItem>
      {categoriesGroup.map((category, i) => {
        let categoryUrl = category.uniqid_url ? category.uniqid_url : null;
        const hasSubcategory =
          category && category.categories && category.categories.length;
        if (!categoryUrl && hasSubcategory) {
          categoryUrl = category.categories[0].url;
        }

        const isCurrent =
          mouseOver === category.id || currentCategoryGroupId === category.id;

        let categoryName = category.name;
        if (
          category.uniqid === 'KAT_AKCIA_SEZONA' &&
          user &&
          user.skvelko_type !== ChematCustomerType.RETAIL
        ) {
          categoryName = __('Novinky / Sezóna');

          /*  
          const newsCategory = category.categories
            ? category.categories.find(
                (item) => item.category_number === 'KAT_NEW_PRODUCT',
              )
            : null;
          if (newsCategory) {
            categoryUrl = newsCategory.url;
          }
          */
        }

        return (
          <CCItem
            key={i}
            className={hover === category.id ? 'hover' : ''}
            onMouseEnter={onMouseEnterDebounce.bind(null, category.id)}
            onMouseLeave={onMouseLeave.bind(null)}
          >
            {categoryUrl ? (
              <CCLink
                to={getUrl(categoryUrl)}
                onClick={onClickLink.bind(null, category, null)}
                className={isCurrent ? 'current' : ''}
              >
                <CCLinkText>{capitalizeFirstLetter(categoryName)}</CCLinkText>
              </CCLink>
            ) : (
              <CCLink className={isCurrent ? 'current' : ''}>
                <CCLinkText>{capitalizeFirstLetter(categoryName)}</CCLinkText>
              </CCLink>
            )}
            {hover === category.id ? (
              <SubList>
                {(hasSubcategory &&
                  category.categories.map((subcategory, j) => {
                    if (
                      (!user ||
                        user.skvelko_type === ChematCustomerType.RETAIL) &&
                      subcategory.category_number === 'KAT_SEASON'
                    ) {
                      return '';
                    }

                    if (
                      user &&
                      user.skvelko_type !== ChematCustomerType.RETAIL &&
                      subcategory.category_number === 'KAT_ACTION'
                    ) {
                      return '';
                    }

                    return (
                      <SubItem key={j}>
                        <ThumbnailWrap
                          to={getUrl(subcategory.url)}
                          onClick={onClickLink.bind(
                            null,
                            category,
                            subcategory.category_id,
                          )}
                        >
                          <Thumbnail
                            src={getImagePath(subcategory.image, {
                              width: 62,
                              height: 62,
                            })}
                          />
                        </ThumbnailWrap>
                        <ItemContent>
                          <Title
                            to={getUrl(subcategory.url)}
                            onClick={onClickLink.bind(
                              null,
                              category,
                              subcategory.category_id,
                            )}
                          >
                            {capitalizeFirstLetter(subcategory.category_name)}
                          </Title>
                          <Items>
                            {(subcategory &&
                              subcategory.children &&
                              subcategory.children.map((item, k) => {
                                if (k < 3) {
                                  return (
                                    <React.Fragment key={k}>
                                      {k !== 0 && <ItemPipe>|</ItemPipe>}
                                      <ItemLink
                                        to={getUrl(item.url)}
                                        onClick={onClickLink.bind(
                                          null,
                                          category,
                                          item.category_id,
                                        )}
                                      >
                                        {capitalizeFirstLetter(
                                          item.category_name,
                                        )}
                                      </ItemLink>
                                    </React.Fragment>
                                  );
                                } else if (k === 3) {
                                  return (
                                    <React.Fragment key={k}>
                                      <ItemPipe>|</ItemPipe>
                                      <ItemLink
                                        to={getUrl(subcategory.url)}
                                        onClick={onClickLink.bind(
                                          null,
                                          category,
                                          item.category_id,
                                        )}
                                      >
                                        {`${__('ďalšie')}...`}
                                      </ItemLink>
                                    </React.Fragment>
                                  );
                                } else {
                                  return '';
                                }
                              })) ||
                              ''}
                          </Items>
                        </ItemContent>
                      </SubItem>
                    );
                  })) ||
                  ''}
              </SubList>
            ) : (
              ''
            )}
          </CCItem>
        );
      })}
    </CCList>
  );
}

const CCList = styled.ul`
  display: none;
  color: ${({ theme }) => theme.color.white};
  align-items: stretch;
  font-size: ${rem(15)};
  font-weight: 700;
  width: 100%;
  margin: 0 0 ${rem(-25)};
  padding: 0;
  list-style: none;
  position: relative;
  ${({ theme }) => theme.media('md')} {
    display: flex;
  }
`;

const CCLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.primary};
  border-right: ${rem(1)} solid ${({ theme }) => rgba(theme.color.black, 0.1)};
  border-left: ${rem(1)} solid ${({ theme }) => rgba(theme.color.white, 0.1)};
  color: inherit;
  text-align: center;
  height: ${rem(50)};
  padding: ${rem(3)} ${rem(8)};
  &:hover,
  &.current {
    background: ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => theme.color.black};
    text-decoration: none;
  }
`;

const CCLinkText = styled.span`
  max-width: ${rem(150)};
`;

const SubList = styled.ul`
  display: none;
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  color: ${({ theme }) => theme.color.black};
  flex-wrap: wrap;
  background: ${({ theme }) => theme.color.white};
  width: 100%;
  padding: ${rem(2)} ${rem(9)} ${rem(6)};
  margin: 0;
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: ${({ theme }) => theme.boxShadow.categories};
`;

const CCItem = styled.li`
  flex: 1 1 auto;
  &:first-child ${CCLink} {
    border-top-left-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    border-bottom-left-radius: ${({ theme }) =>
      rem(theme.borderRadius.default)};
  }
  &:last-child ${CCLink} {
    border-top-right-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    border-bottom-right-radius: ${({ theme }) =>
      rem(theme.borderRadius.default)};
  }
  &.hover ${SubList} {
    display: flex;
  }
`;

const SubItem = styled.li`
  display: flex;
  flex: 0 0 ${(1 / 3) * 100 + '%'};
  padding: 0 ${rem(6)};
  margin: ${rem(8)} 0;
`;

const SubItemSkvelkoWrapper = styled.div`
  display: flex;
  margin: ${rem(16)};
`;

const SubItemSkvelko = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  padding: 0 ${rem(6)};
  font-weight: normal;
`;

const SubItemSkvelkoLink = styled(Link)``;

const SubItemSkvelkoImg = styled(Link)`
  margin: ${rem(10)} auto;
  text-align: center;
  width: 100%;
`;

const SubItemSkvelkoTop = styled.div`
  margin-bottom: ${rem(20)};
`;

const SubItemSkvelkoBottomTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const SubItemSkvelkoBottomBody = styled.div`
  margin-bottom: ${rem(8)} 0;
`;

const ThumbnailWrap = styled(Link)`
  flex: 0 0 auto;
  margin: 0 ${rem(16)};
`;

const Thumbnail = styled.img``;

const ItemContent = styled.div``;

const Title = styled(Link)`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${rem(16)};
  font-weight: 700;
  margin: 0 0 ${rem(4)};
  &.hover {
    cursor: pointer;
  }
`;

const Items = styled.p`
  line-height: ${16 / 14};
  margin: 0;
  display: block;
`;

const ItemPipe = styled.span`
  display: inline-block;
  margin: 0 ${rem(6)};
  color: ${({ theme }) => theme.color.primary};
`;

const ItemLink = styled(Link)`
  color: inherit;
  font-size: ${rem(14)};
  font-weight: 400;
`;

const Image = styled.img`
  width: 315px;
`;

const mapStateToProps = (state) => {
  return {
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    urlMapData: prop(state, 'general.urlMapData'),
    currentCategoryGroupId: state.general.currentCategoryGroupId,
  };
};

export default connect(mapStateToProps)(CustomCategories);
