import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { InputStyledStyles, InputStyles } from '../_helpers/form/FormStyles';
import {
  formatPrice,
  formatStringDate,
  isDeliveryToSchool,
  isDobierka,
  isDpdDelivery,
  isFaktura,
  isGpWebPay,
  isPacketaDelivery,
  isShopPersonalPickup,
  isTrustCard,
  isTrustpay,
  isZbernaFaktura,
} from '../../utilities';
import { SelectStyled } from '../_helpers/form/Select';
import { __ } from 'react-i18n/lib';
import { Label } from '../_helpers/form/Label';
import { Col, Row } from '../../theme/libraries/grid';
import Alert from '../Alert/Alert';
import { ReactSelect } from '../_helpers/form/ReactSelect';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import Icon, { IconWrapper } from '../_helpers/Icon/Icon';
import {
  CartItemRadioLabel,
  ItemRadio,
  ItemRadioDesc,
  ItemRadioLabel,
  ItemRadioLabelWrapper,
  RadioItemsWrapper,
  RadioWrapper,
  RatioItem,
} from '../_helpers/form/Radio';
import { Button, ButtonStyledStyles } from '../_helpers/form/Button';

const ItemPrice = styled.span`
  flex: 1 0 30%;
  text-align: right;
`;

const ItemPriceBold = styled.div`
  font-weight: bold;
`;

const ItemPriceWithVat = styled.div`
  padding-top: 2px;
  font-size: ${rem(12)};
`;

const ItemImages = styled.div`
  flex: 1 0 100%;
  padding-top: ${rem(20)};
`;

const ItemHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.gray64};
`;

const ItemHeader = styled.h6`
  color: ${({ theme }) => theme.color.white};
  text-transform: uppercase;
  font-size: ${rem(14)};
  padding: 0 ${rem(24)};
  margin: 0;
`;

const ToSchoolWrapper = styled.div`
  flex: 1 0 100%;
  padding-top: ${rem(20)};
  width: 100%;
`;

const Image = styled.img`
  border: ${rem(1)} solid ${({ theme }) => theme.color.gray89};
  border-radius: ${rem(2)};
  margin: 0 ${rem(5)} ${rem(5)} 0;
  &.borderless {
    border: none;
  }
`;

const StyledInput = styled.input`
  ${InputStyles};
  ${InputStyledStyles};
  width: ${rem(290)};
  max-width: 100%;
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;

  ${({ theme }) => theme.media('xs')} {
    flex-direction: row;
    width: ${rem(290)};
  }
`;

const StyledSelect = styled(SelectStyled)`
  width: ${rem(290)};
  max-width: 100%;
  ${({ theme }) => theme.media('xs')} {
    margin-top: ${rem(0)};
    width: 48%;
  }
`;

const Selects = styled.div`
  margin-top: 20px;
  width: 100%;
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  position: relative;
  border: 1px solid #f1efef;
  ${({ theme }) => theme.media('sm')} {
    display: flex;
    flex: 1 1 auto;
  }
`;

const FilterSelect = styled(ReactSelect)`
  display: block;
  width: 100%;
  &:focus {
    border-color: transparent;
  }
  border-radius: 0;
  &:first-child {
    border-top-right-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    border-top-left-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  }
  &:last-child {
    border-bottom-right-radius: ${({ theme }) =>
      rem(theme.borderRadius.default)};
    border-bottom-left-radius: ${({ theme }) =>
      rem(theme.borderRadius.default)};
  }
  ${({ theme }) => theme.media('sm')} {
    flex: 1 0 ${({ theme }) => theme.grid.col.col4};
    & + & {
      border-top-color: transparent;
      border-left-color: ${({ theme }) => theme.form.border.color};
    }
    &.sets-and-classes {
      flex: 1 1 auto;
    }
    &.class {
      flex: 0 1 auto;
    }
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: ${({ theme }) =>
        rem(theme.borderRadius.default)};
      border-top-left-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    }
    &:last-child {
      border-radius: 0;
    }
  }
`;

const SecondStyledSelect = styled(StyledSelect)`
  margin-top: ${rem(15)};
`;

const StyledLabel = styled(Label)`
  display: block;
  font-size: ${rem(14)};
`;

const RadioRow = styled(Row)``;

const RadioCol = styled(Col)`
  ${({ theme }) => theme.media(1024)} {
    width: ${({ theme }) => theme.grid.col.col6};
  }
`;

const AlertBold = styled.p`
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
`;

const AlertRed = styled.p`
  color: #a90000;
  margin-top: ${rem(10)};
  margin-bottom: 0;
`;

const AlertTimeInfo = styled.p`
  margin-top: 0;
  margin-bottom: 0;
`;

const ErrorAlertWrapper = styled.div`
  margin-top: ${rem(10)};
`;

const DeliveryPointWrapper = styled.div`
  margin-top: ${rem(10)};
  width: 100%;
`;

const DeliveryRow = styled.div`
  display: flex;
  font-size: ${rem(14)};
  margin-bottom: 3px;
`;

const DeliveryLabel = styled.span`
  flex: 1 0 70%;
`;

const DeliveryBoldLabel = styled.span`
  flex: 1 0 70%;
  font-weight: bold;
`;

const DeliveryIcon = styled.span`
  flex: 1 0 30%;
  text-align: right;
`;

const DeliveryIconWrapper = styled(IconWrapper)`
  width: ${rem(19)};
  height: ${rem(19)};
`;

export const PacketaButton = styled(Button)`
  ${ButtonStyledStyles};
  margin-top: ${rem(12)};
  &.lg {
    padding-right: ${rem(20)};
    padding-left: ${rem(20)};
  }
`;

export const PacketaImage = styled.img`
  height: ${rem(24)};
  margin-left: 0;
  margin-top: ${rem(12)};
  display: block;

  ${({ theme }) => theme.media('xs')} {
    margin-left: ${rem(12)};
    margin-top: 0;
    display: inline;
  }
`;

interface CartRadiosProps {
  data: {
    name: string;
    items: Array<{
      id: string;
      label: string;
      price: number;
      price_without_vat: number;
      description?: string;
      options?: any;
    }>;
  };
  selected?: string;
  classes?: any[];
  setsCount?: number;
  onChange?: (e) => void;

  onChildrenNameChange?: (e) => void;
  onDeliveryPointChange?: (e) => void;
  onPacketaDeliveryPointChange?: (e) => void;
  filterOption?: (e) => void;
  onDeliveryPointInfo?: (e) => void;
  onDeliveryPointRemove?: (e) => void;
  onPacketaDeliveryPointInfo?: (e) => void;
  onPacketaDeliveryPointRemove?: (e) => void;
  onShopPersonalPickupChange?: (e) => void;
  onShopPersonalPickupInfo?: (e) => void;
  onShopPersonalPickupRemove?: (e) => void;
  // onClassChange?: (e) => void;
  onClassPart1Change?: (e) => void;
  onClassPart2Change?: (e) => void;
  selectedChildrenName?: string | null;
  deliveryPoint?: any | null;
  packetaDeliveryPoint?: any | null;
  shopPersonalPickup?: any;
  // selectedClassName?: string | null;
  selectedClassPart1Name?: string | null;
  selectedClassPart2Name?: string | null;
  deliveryToSchoolError?: boolean;
  schoolInfo?: any;
  user?: any;
  showWithVat?: boolean;
  someSetsHasDeliveryToSchoolActivated?: boolean;
}

class CartRadios extends React.PureComponent<CartRadiosProps> {
  public handleSelectClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  public openPacketa = (): void => {
    const exist = document.getElementById('#packeta');

    if (!exist) {
      const script = document.createElement('script');
      script.src = 'https://widget.packeta.com/www/js/library.js';
      script.id = 'packeta';
      document.body.appendChild(script);

      script.onload = () => {
        (window as any).Packeta.Widget.pick(
          'ffecf85ee5abf947',
          this.props.onPacketaDeliveryPointChange,
          {
            country: 'sk',
            language: 'sk',
          },
        );
      };
    }
  };

  public render() {
    const {
      data,
      selected,
      /*classes,*/ schoolInfo,
      showWithVat,
      someSetsHasDeliveryToSchoolActivated,
      setsCount,
      packetaDeliveryPoint,
    } = this.props;

    return (
      <RadioWrapper>
        {data.items.map((item, i) => {
          const isSelected = selected === item.id;
          return (
            <RadioItemsWrapper key={i}>
              {(isFaktura(item.id) || isZbernaFaktura(item.id)) && (
                <ItemHeaderWrapper>
                  <ItemHeader>{__('Odber na faktúru')}</ItemHeader>
                </ItemHeaderWrapper>
              )}
              {isDobierka(item.id) && (
                <ItemHeaderWrapper>
                  <ItemHeader>{__('Pri prevzatí')}</ItemHeader>
                </ItemHeaderWrapper>
              )}
              {isGpWebPay(item.id) && (
                <ItemHeaderWrapper>
                  <ItemHeader>{__('Platba vopred (online)')}</ItemHeader>
                </ItemHeaderWrapper>
              )}
              <ItemRadio
                type="radio"
                id={item.id}
                value={item.id}
                name={data.name}
                onChange={this.props.onChange}
                checked={isSelected}
              />
              <RatioItem htmlFor={item.id}>
                <ItemRadioLabelWrapper>
                  <CartItemRadioLabel>{item.label}</CartItemRadioLabel>
                  {isPacketaDelivery(item.id) ? (
                    <PacketaImage src="/images/red_packeta.svg" />
                  ) : (
                    ''
                  )}
                </ItemRadioLabelWrapper>

                {showWithVat ? (
                  <ItemPrice>{formatPrice(item.price, 'EUR')}</ItemPrice>
                ) : (
                  <ItemPrice>
                    <ItemPriceBold>
                      {formatPrice(item.price_without_vat || 0, 'EUR')}
                    </ItemPriceBold>
                    {item.price !== 0 ? (
                      <ItemPriceWithVat>
                        Cena s DPH {formatPrice(item.price, 'EUR')}
                      </ItemPriceWithVat>
                    ) : (
                      ''
                    )}
                  </ItemPrice>
                )}
                {item.description ? (
                  <ItemRadioDesc>{item.description}</ItemRadioDesc>
                ) : (
                  ''
                )}

                {isTrustCard(item.id) && (
                  <ItemImages>
                    <Image
                      className="borderless"
                      src="/images/cart/payments/icons-cards.png"
                      width="270"
                    />
                  </ItemImages>
                )}
                {isGpWebPay(item.id) && (
                  <ItemImages>
                    <Image
                      className="borderless"
                      src="/images/cart/payments/icons-cards.png"
                      width="270"
                    />
                  </ItemImages>
                )}
                {isTrustpay(item.id) && (
                  <ItemImages>
                    <Image
                      src="/images/cart/payments/tatra-banka.svg"
                      width="48"
                    />
                    <Image src="/images/cart/payments/slsp.svg" width="82" />
                    <Image src="/images/cart/payments/vub.svg" width="91" />
                    <Image
                      src="/images/cart/payments/postova-banka.svg"
                      width="76"
                    />
                    {/*<Image src="/images/cart/payments/csob.svg" width="56" />*/}
                  </ItemImages>
                )}
                {isDpdDelivery(item.id) &&
                isSelected &&
                this.props.onDeliveryPointChange &&
                !this.props.deliveryPoint ? (
                  <Selects onClick={this.handleSelectClick}>
                    <FilterSelect
                      options={item.options}
                      placeholder={__('Zvoľte odberné mesto')}
                      onChange={this.props.onDeliveryPointChange}
                      className="lg"
                      filterOption={this.props.filterOption}
                    />
                  </Selects>
                ) : (
                  ''
                )}
                {isDpdDelivery(item.id) &&
                isSelected &&
                this.props.deliveryPoint ? (
                  <DeliveryPointWrapper>
                    <DeliveryRow>
                      <DeliveryBoldLabel>
                        {this.props.deliveryPoint.city}
                      </DeliveryBoldLabel>
                      <DeliveryIcon>
                        <DeliveryIconWrapper
                          onClick={this.props.onDeliveryPointInfo}
                        >
                          <Icon icon="infoOutline" />
                        </DeliveryIconWrapper>
                      </DeliveryIcon>
                    </DeliveryRow>
                    <DeliveryRow>
                      <DeliveryLabel>
                        {this.props.deliveryPoint.name},{' '}
                        {this.props.deliveryPoint.street}{' '}
                        {this.props.deliveryPoint.houseno},{' '}
                        {this.props.deliveryPoint.city}
                      </DeliveryLabel>
                      <DeliveryIcon>
                        <DeliveryIconWrapper
                          onClick={this.props.onDeliveryPointRemove}
                        >
                          <Icon icon="errorCircle" />
                        </DeliveryIconWrapper>
                      </DeliveryIcon>
                    </DeliveryRow>
                  </DeliveryPointWrapper>
                ) : (
                  ''
                )}

                {isPacketaDelivery(item.id) &&
                isSelected &&
                !packetaDeliveryPoint ? (
                  <PacketaButton onClick={this.openPacketa}>
                    {__('Zvoľte výdajné miesto')}
                  </PacketaButton>
                ) : (
                  ''
                )}

                {isPacketaDelivery(item.id) &&
                isSelected &&
                packetaDeliveryPoint ? (
                  <DeliveryPointWrapper>
                    <DeliveryRow>
                      <DeliveryBoldLabel>
                        {packetaDeliveryPoint.place}
                      </DeliveryBoldLabel>
                      <DeliveryIcon>
                        <DeliveryIconWrapper
                          onClick={this.props.onPacketaDeliveryPointInfo}
                        >
                          <Icon icon="infoOutline" />
                        </DeliveryIconWrapper>
                      </DeliveryIcon>
                    </DeliveryRow>
                    <DeliveryRow>
                      <DeliveryLabel>
                        {packetaDeliveryPoint.nameStreet},{' '}
                        {packetaDeliveryPoint.zip}{' '}
                      </DeliveryLabel>
                      <DeliveryIcon>
                        <DeliveryIconWrapper
                          onClick={this.props.onPacketaDeliveryPointRemove}
                        >
                          <Icon icon="errorCircle" />
                        </DeliveryIconWrapper>
                      </DeliveryIcon>
                    </DeliveryRow>
                  </DeliveryPointWrapper>
                ) : (
                  ''
                )}

                {isShopPersonalPickup(item.id) &&
                isSelected &&
                this.props.onShopPersonalPickupChange &&
                !this.props.shopPersonalPickup ? (
                  <Selects onClick={this.handleSelectClick}>
                    <FilterSelect
                      options={item.options}
                      placeholder={__('Zvoľte predajňu')}
                      onChange={this.props.onShopPersonalPickupChange}
                      className="lg"
                      filterOption={this.props.filterOption}
                    />
                  </Selects>
                ) : (
                  ''
                )}
                {isShopPersonalPickup(item.id) &&
                isSelected &&
                this.props.shopPersonalPickup ? (
                  <DeliveryPointWrapper>
                    <DeliveryRow>
                      <DeliveryBoldLabel>
                        {this.props.shopPersonalPickup.name}
                      </DeliveryBoldLabel>
                      <DeliveryIcon>
                        <DeliveryIconWrapper
                          onClick={this.props.onShopPersonalPickupInfo}
                        >
                          <Icon icon="infoOutline" />
                        </DeliveryIconWrapper>
                      </DeliveryIcon>
                    </DeliveryRow>
                    <DeliveryRow>
                      <DeliveryLabel>
                        {this.props.shopPersonalPickup.address},{' '}
                        {this.props.shopPersonalPickup.zip}{' '}
                        {this.props.shopPersonalPickup.city}
                      </DeliveryLabel>
                      <DeliveryIcon>
                        <DeliveryIconWrapper
                          onClick={this.props.onShopPersonalPickupRemove}
                        >
                          <Icon icon="errorCircle" />
                        </DeliveryIconWrapper>
                      </DeliveryIcon>
                    </DeliveryRow>
                  </DeliveryPointWrapper>
                ) : (
                  ''
                )}

                {isDeliveryToSchool(item.id) && isSelected ? (
                  <ToSchoolWrapper>
                    {schoolInfo && (
                      <RadioRow>
                        <Col>
                          <Alert type="info">
                            <AlertBold>
                              {' '}
                              {__(
                                `${schoolInfo.name}, ${schoolInfo.street} ${schoolInfo.streetNumber}, ${schoolInfo.city} - umožňuje doručiť žiakom balíček pomôcok bezplatne do jej priestorov. Balíček bude žiakom rozdaný na začiatku školského roka.`,
                              )}
                            </AlertBold>
                            <br />
                            <AlertTimeInfo>
                              {__(
                                `Objednávky odoslané do ${formatStringDate(
                                  schoolInfo.deliveryToSchoolUntil,
                                )} je možné doručiť týmto spôsobom.`,
                              )}
                            </AlertTimeInfo>
                            {setsCount && setsCount > 1 ? (
                              <AlertRed>
                                Váš košík obsahuje viac ako jednu sadu školských
                                potrieb. Do kolónky nižšie uveďte, prosím, meno
                                žiaka, ktorému bude celá objednávka odovzdaná.
                                Jednotlivé sady budú v objednávke nabalené
                                zvlášť.
                              </AlertRed>
                            ) : (
                              <></>
                            )}
                          </Alert>
                        </Col>
                      </RadioRow>
                    )}

                    {this.props.deliveryToSchoolError && (
                      <ErrorAlertWrapper className="error">
                        <RadioRow>
                          <Col>
                            <Alert type="error">
                              {' '}
                              {__(
                                'Pre dopravu priamo do školy je potrebné vyplniť meno žiaka a triedu.',
                              )}{' '}
                            </Alert>
                          </Col>
                        </RadioRow>
                      </ErrorAlertWrapper>
                    )}

                    <RadioRow>
                      <RadioCol>
                        <StyledLabel>
                          {__('Meno a priezvisko žiaka')}:
                        </StyledLabel>
                        <StyledInput
                          onChange={this.props.onChildrenNameChange}
                          defaultValue={this.props.selectedChildrenName || ''}
                        />
                      </RadioCol>
                      <RadioCol>
                        <StyledLabel>
                          {`${__(
                            'Trieda pre školský rok',
                          )} ${new Date().getFullYear()}/${
                            new Date().getFullYear() + 1
                          }`}
                          :
                        </StyledLabel>

                        <SelectWrapper>
                          <StyledSelect
                            onChange={this.props.onClassPart1Change}
                            defaultValue={
                              this.props.selectedClassPart1Name || ''
                            }
                          >
                            <option value="">{__('Zvoľte ročník')}</option>
                            <option value={'1.'}>1.</option>
                            <option value={'2.'}>2.</option>
                            <option value={'3.'}>3.</option>
                            <option value={'4.'}>4.</option>
                            <option value={'5.'}>5.</option>
                            <option value={'6.'}>6.</option>
                            <option value={'7.'}>7.</option>
                            <option value={'8.'}>8.</option>
                            <option value={'9.'}>9.</option>
                          </StyledSelect>

                          <SecondStyledSelect
                            onChange={this.props.onClassPart2Change}
                            defaultValue={
                              this.props.selectedClassPart2Name || ''
                            }
                          >
                            <option value="">{__('Zvoľte triedu')}</option>
                            <option value={'neviem'}>neviem</option>
                            <option value={'A'}>A</option>
                            <option value={'B'}>B</option>
                            <option value={'C'}>C</option>
                            <option value={'D'}>D</option>
                            <option value={'E'}>E</option>
                            <option value={'F'}>F</option>
                            <option value={'G'}>G</option>
                            <option value={'H'}>H</option>
                          </SecondStyledSelect>
                        </SelectWrapper>

                        {/*
                        {classes && classes.length > 0 ? (
                          <StyledSelect
                            onChange={this.props.onClassChange}
                            defaultValue={
                              this.props.selectedClassName
                                ? this.props.selectedClassName
                                : ''
                            }
                          >
                            <option value="">{__('Trieda')}</option>

                            {classes.map((classObj, u) => {
                              return (
                                <option key={u} value={classObj.name}>
                                  {classObj.name}
                                </option>
                              );
                            })}
                          </StyledSelect>
                        ) : (
                          <StyledInput
                            onChange={this.props.onClassChange}
                            defaultValue={
                              this.props.selectedClassName
                                ? this.props.selectedClassName
                                : ''
                            }
                          />
                        )}
                        */}
                      </RadioCol>
                    </RadioRow>
                  </ToSchoolWrapper>
                ) : (
                  ''
                )}
              </RatioItem>
            </RadioItemsWrapper>
          );
        })}
        {someSetsHasDeliveryToSchoolActivated &&
        !data.items.some((i) => isDeliveryToSchool(i?.id)) ? (
          <RadioItemsWrapper>
            <ItemRadio
              type="radio"
              id={'DELIVERY_TO_SCHOOL'}
              value={'DELIVERY_TO_SCHOOL'}
              name={'DELIVERY_TO_SCHOOL'}
              checked={false}
              disabled={true}
              className={'disabled'}
            />
            <RatioItem htmlFor={'DELIVERY_TO_SCHOOL'}>
              <ItemRadioLabel>{__('Doručenie do školy')}</ItemRadioLabel>
              <ItemPrice>{formatPrice(0, 'EUR')}</ItemPrice>
              <ItemRadioDesc>
                {__(
                  'V nákupnom košíku máte zoznamy pomôcok z rôznych škôl, a preto DOPRAVA DO ŠKOLY ZDARMA nie je možná. Ak máte záujem o DOPRAVU DO ŠKOLY ZDARMA, poprosíme Vás urobiť samostatnú objednávku a platbu pre každé dieťa zvlášť. Pomôcky odovzdá učiteľ žiakovi podľa jeho mena a triedy.',
                )}
              </ItemRadioDesc>
            </RatioItem>
          </RadioItemsWrapper>
        ) : (
          ''
        )}
      </RadioWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deliveryPoint: state.cart.deliveryPoint,
    shopPersonalPickup: state.cart.shopPersonalPickup,
    packetaDeliveryPoint: state.cart.cart.packeta_delivery_point,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartRadios' })(CartRadios),
);
