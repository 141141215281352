import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  ProductSmallAmount,
  ProductSmallName,
} from '../../Product/ProductStyles';
import { ThumbStyles } from '../product/product-styles';
import BaseModal from './BaseModal';
import ImagePlaceholder from '../Image/ImagePlaceholder';
import {
  formatPrice,
  getProductPrices,
  isDiscountInCart,
  isMobile,
} from '../../../utilities';
import { Button1, Button2, StyledFooter } from './ChooseModal';
import Variant from '../product/Variant';
import {
  getGoodVariant,
  loadRelatedProductsModal,
} from '../../../containers/Product/actions';
import CartShippingRange from '../../Cart/CartShippingRange';
import { Loader } from '../Loader/Loader';
import { ProductsRow } from '../../Category/Category';
import { ProductColWrapper } from '../../../containers/Category/Category';
import ProductPreview from '../product/ProductPreview';
import { Link } from 'react-router';

const ModalDiv = styled(BaseModal)`
  width: ${rem(650)};
`;

const BodyWrapper = styled.div`
  display: flex;
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden;
`;

const AmountPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const ThumbWrapper = styled.div`
  width: 100%;
  margin-right: ${rem(15)};
  flex: 0 0 ${rem(84)};
  ${({ theme }) => theme.media('sm')} {
    flex: 0 0 ${rem(150)};
  }
`;

const Thumb = styled(ImagePlaceholder)`
  ${ThumbStyles};
`;

const Name = styled.p`
  ${ProductSmallName};
  width: 100%;
`;

const Amount = styled.p`
  ${ProductSmallAmount};
  padding-right: ${rem(15)};
`;

const Price = styled.p`
  margin: 0;
  padding-right: ${rem(4)};
  font-weight: 700;
`;

const PriceSale = styled(Price)`
  color: ${({ theme }) => theme.color.primary};
`;

const PriceDiscount = styled(PriceSale)`
  color: ${({ theme }) => theme.color.success};
`;

const PriceOld = styled(Price)`
  font-weight: 400;
  text-decoration: line-through;
`;

const ContinueShopping = styled(Button1)`
  &.lg {
    padding-right: ${rem(20)};
    padding-left: ${rem(20)};
  }
`;

const GoToCart = styled(Button2)``;

const FooterStyled = styled(StyledFooter)`
  border-top: 0;
  padding-top: 0;
`;

const RelatedProductsWrapper = styled.div`
  padding: ${rem(10)} ${rem(20)} ${rem(20)};
  background: ${({ theme }) => theme.color.gray93};
`;

const RelatedProductsRow = styled(ProductsRow)`
  margin-top: 0px;
  margin-bottom: 0px;

  ${({ theme }) => theme.media('sm')} {
    margin-top: ${rem(8)};
  }
`;

const RelatedProductsHeading = styled.h5`
  margin: 0;
  font-weight: normal;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.gray56};
`;

const CartShippingRangeStyled = styled(CartShippingRange)`
  align-self: center;
  flex: 1 0 auto;

  ${({ theme }) => theme.media('sm')} {
    margin: ${rem(38)} 0 0 0;
    min-width: ${rem(320)};
  }
`;

const Title = styled.strong`
  font-size: ${rem(14)};
  font-weight: 800;

  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(18)};
  }
`;

interface ModalProps {
  product: any;
  count: number;
  onCloseButtonClick?: (event) => void;
  dispatch: any;
  user: any;
  cart: any;
  goodId?: number | string;
  freeShippingPrice: number;
  relatedProductsModal?: any;
  relatedProductsModalLoader?: boolean;
  isUpsell?: boolean;
}

const ProductAddedToCartModal = (props: ModalProps) => {
  const placeholder = '/images/placeholder-46.png';
  const placeholder2x = '/images/placeholder-46@2x.png';
  const {
    product,
    count,
    cart,
    goodId,
    user,
    freeShippingPrice,
    relatedProductsModal,
    relatedProductsModalLoader,
    onCloseButtonClick,
    isUpsell,
  } = props;

  React.useEffect(() => {
    props.dispatch(loadRelatedProductsModal(props.product?.product_id));
  }, []);

  /*
  const onGoToCartClick = (e) => {
    props.dispatch(push('/kosik'));
    props.dispatch(setAddToCartModalProduct(null));
  };
  */

  const products = relatedProductsModal?.products;
  const {
    currencyId,
    price,
    retailPrice,
    oldPrice,
    discountPrice,
    isSale,
    isCustomerPrice,
    isWarehouse,
  } = getProductPrices(product, product?.main_good, cart, user, count);

  let selectedGood = null;

  if (goodId) {
    const goodIdValue = Number(goodId);
    selectedGood = product.goods.find(
      (item) => item && item.good_id && item.good_id === goodIdValue,
    );
  }
  let variant: any = null;
  if (selectedGood) {
    variant = getGoodVariant(selectedGood);
    if (variant?.values?.length) {
      variant.attrib_value = variant.values[0].attrib_value || null;
    }
  }

  const mobile = isMobile();
  const thumbSize = mobile ? 84 : 150;

  return (
    <ModalDiv
      title={
        <Title>
          {product.on_demand
            ? 'Pridané do zoznamu pre kalkuláciu'
            : !isUpsell
            ? 'Produkt bol pridaný do košíka'
            : 'Produkty boli pridané do košíka'}
        </Title>
      }
      body={
        <>
          <BodyWrapper>
            <ThumbWrapper>
              <Thumb
                w={thumbSize}
                h={thumbSize}
                width={thumbSize}
                height={thumbSize}
                src={product.picture}
                placeholder={placeholder}
                retinaPlaceholder={placeholder2x}
              />
            </ThumbWrapper>
            <ProductInfo>
              <Name>{product.name}</Name>
              {variant ? <Variant variant={variant} /> : ''}
              <AmountPriceWrapper>
                <Amount>
                  {count}&nbsp;{__('ks')}
                </Amount>
                {!product?.on_demand ? (
                  isCustomerPrice && retailPrice && !product?.on_demand ? (
                    <>
                      <PriceOld>
                        {formatPrice(retailPrice, currencyId)}
                      </PriceOld>
                      <PriceDiscount>
                        {formatPrice(price, currencyId)}
                      </PriceDiscount>
                    </>
                  ) : isSale ? (
                    <>
                      <PriceOld>{formatPrice(oldPrice, currencyId)}</PriceOld>
                      <PriceSale>{formatPrice(price, currencyId)}</PriceSale>
                    </>
                  ) : isDiscountInCart(cart, user) && discountPrice ? (
                    <>
                      <PriceOld>{formatPrice(price, currencyId)}</PriceOld>
                      <PriceDiscount>
                        {formatPrice(discountPrice, currencyId)}
                      </PriceDiscount>
                    </>
                  ) : isWarehouse && retailPrice ? (
                    <>
                      <PriceOld>
                        {formatPrice(retailPrice, currencyId)}
                      </PriceOld>
                      <PriceDiscount>
                        {formatPrice(price, currencyId)}
                      </PriceDiscount>
                    </>
                  ) : (
                    <Price>{formatPrice(price, currencyId)}</Price>
                  )
                ) : (
                  ''
                )}
              </AmountPriceWrapper>
              {!mobile && !product?.on_demand ? (
                <CartShippingRangeStyled
                  className={'no-shadow'}
                  cart={cart}
                  user={user}
                  freeShippingPrice={freeShippingPrice}
                />
              ) : (
                ''
              )}
            </ProductInfo>
          </BodyWrapper>
          {mobile && !product?.on_demand ? (
            <ProductInfo>
              <CartShippingRangeStyled
                className={'no-shadow'}
                cart={cart}
                user={user}
                freeShippingPrice={freeShippingPrice}
              />
            </ProductInfo>
          ) : (
            ''
          )}
        </>
      }
      footer={
        <>
          <FooterStyled>
            <Link to={'/kosik'}>
              <GoToCart className="primary lg" to={'/kosik'}>
                {product.on_demand
                  ? __('Dokončiť kalkuláciu')
                  : __('Prejsť do košíka')}
              </GoToCart>
            </Link>
            <ContinueShopping className="lg" onClick={onCloseButtonClick}>
              {product.on_demand
                ? __('Pridať ďalší produkt')
                : __('Pokračovať v nakupovaní')}
            </ContinueShopping>
          </FooterStyled>
          <Loader loading={!!relatedProductsModalLoader}>
            {products?.length ? (
              <RelatedProductsWrapper>
                <RelatedProductsHeading>
                  {__('Odporúčané produkty')}
                </RelatedProductsHeading>
                <RelatedProductsRow onClick={onCloseButtonClick}>
                  {products.slice(0, 4).map((product) => (
                    <ProductColWrapper key={product.product_id}>
                      <ProductPreview
                        product={product}
                        small={true}
                        smallShowCart={true}
                        notLazyloading={true}
                      />
                    </ProductColWrapper>
                  ))}
                </RelatedProductsRow>
              </RelatedProductsWrapper>
            ) : (
              ''
            )}
          </Loader>
        </>
      }
      onCloseButtonClick={onCloseButtonClick}
    />
  );
};

export default ProductAddedToCartModal;
