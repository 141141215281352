import * as React from 'react';
import styled, { css } from 'styled-components';
import { __ } from 'react-i18n';
import { InputStyled } from '../../components/_helpers/form/Input';
import { CheckboxStyled } from '../../components/_helpers/form/Checkbox';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import { Form, Field, FormSpy } from 'react-final-form';
import { rem } from 'polished';
import API from '../../services/API';
import Alert from '../../components/Alert/Alert';
import { Link } from 'react-router';
import EmailAutocomplete from '../../components/_helpers/form/EmailAutocomplete';
import {
  currentYear,
  formatBirthDay,
  range,
  scrollToError,
  scrollToTop,
} from '../../utilities';
import { SelectStyled } from '../../components/_helpers/form/Select';
import {
  composeValidators,
  email,
  phone,
  postalCode,
  required,
} from '../../utilities/validators';
import { ErrorPopup } from '../../components/_helpers/form/FormStyles';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { parsePostalCode } from '../../utilities/formParsers';

const Wrapper = styled.div`
  width: 100%;
`;
const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 10px;
  flex-direction: column;

  ${({ theme }) => theme.media('sm')} {
    flex-direction: row;
  }
`;

const FormSubWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${({ theme }) => theme.media('sm')} {
    flex-direction: row;
  }
`;

const CheckboxWrapper = styled.div``;

const CheckboxRow = styled.div``;

const SubmitButtonWrapper = styled.div`
  margin-top: 15px;
`;

const FormCol = styled.div`
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
`;

const FormSubSmallCol = styled.div`
  flex: 2;
  ${({ theme }) => theme.media('sm')} {
    margin-right: 10px;
  }
`;

const FormSubLargeCol = styled.div`
  flex: 3;
`;

const InputRow = styled.div`
  margin-bottom: 15px;
`;

const ColumnTitle = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
`;

const FormLabel = styled.div``;

const FormInputCss = css`
  width: 100%;
  ${(props: any) => {
    return props.error ? 'border:2px solid red;' : '';
  }}
`;

const FormInput = styled(InputStyled)`
  ${FormInputCss};
`;

const FormInputEmail = styled(EmailAutocomplete)`
  ${FormInputCss};
`;

const FormSelect = styled(SelectStyled)`
  ${FormInputCss};
`;

const FormField = styled.form``;

const FormTitle = styled.div`
  font-weight: bold;
  font-size: ${rem(23)};
  margin-bottom: 15px;
`;

export const CustomerCardForm = (props) => {
  const year = currentYear();

  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(0);
  const [customerState, setCustomerState] = React.useState(null);

  const onSubmit = async (values) => {
    let copyValues = JSON.parse(JSON.stringify(values));

    const day = values.birth_date_day;
    const month = values.birth_date_month;
    const yearr = values.birth_date_year;
    copyValues = {
      ...copyValues,
      birth_date: formatBirthDay(day, month, yearr),
      gdpr_accepted: !!values.gdpr_accepted,
      gdpr_card_accepted: !!values.gdpr_card_accepted,
      gdpr_profil_accepted: !!values.gdpr_profil_accepted,
      gdpr_weekend_emails_accepted: !!values.gdpr_weekend_emails_accepted,
      min_age_accepted: !!values.min_age_accepted,
    };

    setLoading(true);
    try {
      const formResponse: any = await API.customerCardForm(
        {
          xAcceptLanguage: 'sk',
        },
        copyValues,
      );

      if (
        formResponse &&
        formResponse.chematResponse &&
        formResponse.chematResponse.customerState
      ) {
        setCustomerState(formResponse.chematResponse.customerState);
      }

      setStatus(1);
      props.onSendSuccess(true);
    } catch (e) {
      setStatus(-1);
    }
    setLoading(false);
    scrollToTop();
  };

  return (
    <Wrapper>
      <Loader loading={loading}>
        <FormTitle>Žiadosť o vydanie zákazníckej kartičky</FormTitle>

        {status === 1 && customerState === 1 && (
          <Alert type="success">
            {' '}
            {__(
              'Daná emailová adresa už má založenú zákaznícku kartičku. V prípade straty prosím vyplňte tento ',
            )}
            <Link to={'/strata-zakaznickej-karty-formular'}>formulár</Link>
            {'. '}
          </Alert>
        )}

        {status === 1 && !customerState && (
          <Alert type="success">
            {' '}
            {__('Žiadosť o zákaznícku kartičku bola úspešne odoslaná.')}{' '}
          </Alert>
        )}

        {status === -1 && (
          <Alert type="error">
            {' '}
            {__('Žiadosť o zákaznícku kartičku sa nepodarilo odoslať')}{' '}
          </Alert>
        )}

        {(status !== 1 || customerState) && (
          <Form
            onSubmit={onSubmit}
            // tslint:disable-next-line:jsx-no-lambda
            render={({ handleSubmit }) => (
              <FormField onSubmit={handleSubmit}>
                <FormWrapper>
                  <FormCol>
                    <ColumnTitle>{__('Osobné údaje')}</ColumnTitle>
                    <Field name="name" validate={required}>
                      {({ input, meta }) => (
                        <InputRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <FormLabel> Meno a priezvisko </FormLabel>
                          <FormInput
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </InputRow>
                      )}
                    </Field>
                    <Field
                      name="tel"
                      validate={composeValidators(phone, required)}
                    >
                      {({ input, meta }) => (
                        <InputRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <FormLabel> Telefón / mobil </FormLabel>
                          <FormInput
                            {...input}
                            type="tel"
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </InputRow>
                      )}
                    </Field>

                    <Field name="sex" validate={required}>
                      {({ input, meta }) => (
                        <InputRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <FormLabel> Pohlavie </FormLabel>
                          <FormSelect
                            {...input}
                            error={meta.error && meta.touched}
                          >
                            <option value="" />
                            <option value="Z">{__('Žena')}</option>
                            <option value="M">{__('Muž')}</option>
                          </FormSelect>
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </InputRow>
                      )}
                    </Field>

                    <FormLabel> Dátum narodenia </FormLabel>
                    <FormSubWrapper>
                      <FormSubSmallCol>
                        <Field name="birth_date_day" validate={required}>
                          {({ input, meta }) => (
                            <InputRow
                              className={
                                meta.error && meta.touched ? 'error' : ''
                              }
                            >
                              <FormSelect
                                {...input}
                                error={meta.error && meta.touched}
                              >
                                <option value="">{__('deň')}</option>
                                {range(1, 32).map((day) => {
                                  return (
                                    <option value={day} key={day}>
                                      {day}.
                                    </option>
                                  );
                                })}
                              </FormSelect>
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputRow>
                          )}
                        </Field>
                      </FormSubSmallCol>

                      <FormSubSmallCol>
                        <Field name="birth_date_month" validate={required}>
                          {({ input, meta }) => (
                            <InputRow
                              className={
                                meta.error && meta.touched ? 'error' : ''
                              }
                            >
                              <FormSelect
                                {...input}
                                error={meta.error && meta.touched}
                              >
                                <option value="">{__('mesiac')}</option>
                                {range(1, 12).map((month) => {
                                  return (
                                    <option value={month} key={month}>
                                      {month}.
                                    </option>
                                  );
                                })}
                              </FormSelect>
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputRow>
                          )}
                        </Field>
                      </FormSubSmallCol>

                      <FormSubLargeCol>
                        <Field name="birth_date_year" validate={required}>
                          {({ input, meta }) => (
                            <InputRow
                              className={
                                meta.error && meta.touched ? 'error' : ''
                              }
                            >
                              <FormSelect
                                {...input}
                                error={meta.error && meta.touched}
                              >
                                <option value="">{__('rok')}</option>
                                {range(year - 100, year)
                                  .reverse()
                                  .map((yearrr) => {
                                    return (
                                      <option value={yearrr} key={yearrr}>
                                        {yearrr}
                                      </option>
                                    );
                                  })}
                              </FormSelect>
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputRow>
                          )}
                        </Field>
                      </FormSubLargeCol>
                    </FormSubWrapper>

                    <Field
                      name="email"
                      validate={composeValidators(required, email)}
                    >
                      {({ input, meta }) => (
                        <InputRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <FormLabel> Email </FormLabel>
                          <FormInputEmail
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </InputRow>
                      )}
                    </Field>
                  </FormCol>

                  <FormCol>
                    <ColumnTitle>
                      {__('Adresa pre doručenie kartičky')}
                    </ColumnTitle>

                    <Field name="street" validate={required}>
                      {({ input, meta }) => (
                        <InputRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <FormLabel> Ulica a číslo domu </FormLabel>
                          <FormInput
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </InputRow>
                      )}
                    </Field>

                    <Field name="city" validate={required}>
                      {({ input, meta }) => (
                        <InputRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <FormLabel> Mesto/obec </FormLabel>
                          <FormInput
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </InputRow>
                      )}
                    </Field>

                    <Field
                      name="zip"
                      validate={composeValidators(required, postalCode)}
                      parse={parsePostalCode}
                    >
                      {({ input, meta }) => (
                        <InputRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <FormLabel> PSČ </FormLabel>
                          <FormInput
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </InputRow>
                      )}
                    </Field>
                  </FormCol>
                </FormWrapper>

                <CheckboxWrapper>
                  <Field
                    name="min_age_accepted"
                    type="checkbox"
                    validate={required}
                  >
                    {({ input, meta }) => (
                      <CheckboxRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <CheckboxStyled
                          {...input}
                          value="1"
                          id="min_age_accepted"
                        >
                          {__('Potvrdzujem, že som už dovŕšil vek 16 rokov.')}
                        </CheckboxStyled>
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </CheckboxRow>
                    )}
                  </Field>

                  <Field
                    name="gdpr_card_accepted"
                    type="checkbox"
                    validate={required}
                  >
                    {({ input, meta }) => (
                      <CheckboxRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <CheckboxStyled
                          {...input}
                          value="1"
                          id="gdpr_card_accepted"
                        >
                          {__(
                            'Súhlasím so spracovaním osobných údajov za účelom vystavenia zákazníckej karty Daffer.',
                          )}
                        </CheckboxStyled>
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </CheckboxRow>
                    )}
                  </Field>

                  <Field name="gdpr_weekend_emails_accepted" type="checkbox">
                    {({ input, meta }) => (
                      <CheckboxRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <CheckboxStyled
                          {...input}
                          value="1"
                          id="gdpr_weekend_emails_accepted"
                        >
                          {__(
                            'Súhlasím so spracovaním osobných údajov za účelom zasielania výhodných a víkendových ponúk na moju emailovú adresu.',
                          )}
                        </CheckboxStyled>
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </CheckboxRow>
                    )}
                  </Field>

                  <Field name="gdpr_profil_accepted" type="checkbox">
                    {({ input, meta }) => (
                      <CheckboxRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <CheckboxStyled
                          {...input}
                          value="1"
                          id="gdpr_profil_accepted"
                        >
                          {__(
                            'Súhlasím so spracovaním osobných údajov za účelom automatizovaného rozhodovania vrátane profilovania.',
                          )}
                        </CheckboxStyled>
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </CheckboxRow>
                    )}
                  </Field>

                  <Field
                    name="gdpr_accepted"
                    type="checkbox"
                    validate={required}
                  >
                    {({ input, meta }) => (
                      <CheckboxRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <CheckboxStyled {...input} value="1" id="gdpr_accepted">
                          {__('Potvrdzujem, že som bol oboznámený s')}{' '}
                          <Link to="/gdpr" target="_blank">
                            podmienkami ochrany osobných údajov
                          </Link>{' '}
                          {__('podľa GDPR')}
                        </CheckboxStyled>
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </CheckboxRow>
                    )}
                  </Field>
                </CheckboxWrapper>

                <SubmitButtonWrapper>
                  <ButtonStyled className="primary lg" type="submit">
                    {__('Odoslať')}
                  </ButtonStyled>
                </SubmitButtonWrapper>

                <FormSpy
                  subscription={{ submitFailed: true, modified: false }}
                  onChange={() => {
                    scrollToError();
                  }}
                />
              </FormField>
            )}
          />
        )}
      </Loader>
    </Wrapper>
  );
};
