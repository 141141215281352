import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Heading2 } from '../_helpers/Heading/Heading';
import CartProductList from './CartProductList';
import {
  debounce,
  flatten,
  formatPrice,
  getBaseUrl,
  getGiftCartRemain,
  isCartGiftsValid,
  isCompany,
  isEmailValid,
  isPriceRangeRetail,
  isRetail,
  scrollToTop,
  showPriceWithVat,
} from '../../utilities';
import {
  Button,
  ButtonStyledStyles,
  ButtonStyles,
} from '../_helpers/form/Button';
import { Link } from 'react-router';
import {
  addGiftcardError,
  createOrder,
  hasCartAvailabilityProblems,
  isProblemTypeAvailability,
  loadEshopGifts,
  recognizeCustomerCard,
  updateCart,
} from '../../containers/Cart/actions';
import Icon from '../_helpers/Icon/Icon';
import { push } from 'react-router-redux';
import CartShippingRange from './CartShippingRange';
import CartVoucherForm from './CartVoucherForm';
import CartVoucherApplied from './CartVoucherApplied';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import Alert from '../Alert/Alert';
import AlertAvailability, {
  AlertAvailabilityType,
} from '../Alert/AlertAvailability';
import { HeaderSimpleType } from '../_layout/Header/HeaderSimple';
import { Loader } from '../_helpers/Loader/Loader';
import CartGift from './CartGift';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import API, { ThenArg } from '../../services/API';
import CartProductOnDemandList from './CartProductOnDemandList';
import { emptyCartTitleMsg } from '../../defaults';
import CartGiftModal from '../_helpers/Modal/CartGiftModal';
import * as cookie from 'react-cookies';
import { Field, Form } from 'react-final-form';
import {
  composeValidators,
  email,
  phone,
  phoneMobile,
  required,
  requiredSelect,
} from '../../utilities/validators';
import { CartFormCol, CartFormRow, CartInput, CartLabel } from './CartStyles';
import { ErrorPopup } from '../_helpers/form/FormStyles';
import { grid } from '../../theme/libraries/grid';
import EmailAutocomplete from '../_helpers/form/EmailAutocomplete';
import { ChematCustomerType } from '../../library/ChematCustomerType';
import { TextareaStyledBlock } from '../_helpers/form/Textarea';
import { CheckboxStyled } from '../_helpers/form/Checkbox';
import { openGdprModal } from '../../containers/App/actions';
import { SelectStyled } from '../_helpers/form/Select';
import UpsellSlider from '../_helpers/product/UpsellSlider';

dayjs.extend(isBetween);
interface Cart1Props {
  cart: any;
  dispatch: any;
  user: any;
  setTitleText: (title: string) => void;
  setHeaderSimpleType: (simpleType: HeaderSimpleType) => void;
  error?: any;
  deliveryFreePrice?: any;
  prevPath: string;
  eshopGifts: any;
  onDemandCartItems: ThenArg<typeof API.getCart>['items'];
  regularCartItems: ThenArg<typeof API.getCart>['items'];
  settings: any;
  lastValues: any;
  cart1Upsell: any;
}

const Cart1 = (props: Cart1Props) => {
  const [nextStepLoading, setNextStepLoading] = React.useState(false);
  const [isGiftModalVisible, setIsGiftModalVisible] = React.useState(false);
  const [showNote, setShowNote] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [problems, setProblems] = React.useState([] as any[]);
  const [showErrors, setShowErrors] = React.useState(false);
  const [organizationSelectValue, setOrganizationSelectValue] = React.useState(
    -1,
  );

  const {
    dispatch,
    user,
    error,
    cart,
    deliveryFreePrice,
    prevPath,
    eshopGifts,
    onDemandCartItems,
    regularCartItems,
    settings,
    cart1Upsell,
  } = props;

  const modalGiftCookieString = 'md_' + cart.id;
  const showWithVat = showPriceWithVat(user);
  const company = isCompany(user);
  const isDemand: boolean =
    (!regularCartItems || regularCartItems.length === 0) &&
    onDemandCartItems.length > 0;

  const isGiftsVisible =
    (!user || isRetail(user)) && isCartGiftsValid(settings, user) && eshopGifts;

  React.useEffect(() => {
    if (props.setTitleText) {
      props.setTitleText(
        isDemand ? __('Cenová kalkulácia') : __('Nákupný košík'),
      );
    }
    if (props.setHeaderSimpleType) {
      props.setHeaderSimpleType(HeaderSimpleType.CART);
    }
    props.dispatch(addGiftcardError(null));
    if (!props.eshopGifts) {
      props.dispatch(loadEshopGifts());
    }

    //props.dispatch(loadCart1Upsell());
  }, []);

  const emailChanged = (dispatch, newEmail) =>
    dispatch(recognizeCustomerCard(newEmail));
  const [recognizeCustomerCardDebounce] = debounce(emailChanged, 1000);

  const [updateCartDebounce, updateCartDebounceCancel] = debounce(
    (dispatch, newCart) => dispatch(updateCart(newCart, true)),
    5000,
  );

  console.log(updateCartDebounce);

  const userEmail =
    props.user && props.user.email.includes('@') ? props.user.email : '';

  const onShowNoteValuesChange = (e) => {
    setShowNote(!showNote);
  };

  const getNewCart = (values) => {
    const phone = (values.person_phone || '')
      .replace(/\s/g, '')
      .replace(/\//g, '')
      .replace(/\\/g, '');

    let newCart = {
      ...cart,
      billing_address: {
        person: {
          name: values.person_name,
          surname: values.person_surname,
          phone: phone,
          email: values.person_email,
        },
        street: values.billing_street,
        street_number: values.billing_street_number,
        city: values.billing_city,
        zip: values.billing_zip,
        country: values.billing_country,
      },
      delivery_address: {
        person: {
          name: '',
          surname: '',
          phone: '',
          email: '',
        },
        street: '',
        street_number: '',
        city: '',
        zip: '',
        country: '',
      },
      use_delivery_address: false,
      terms_accept: values.terms_accept,
      competition_accept: values.competition_accept,
      newsletter_accept: true,
      company_newsletter_accept: values.company_newsletter_accept,
      note: values.note ? values.note : '',
      company: {
        name: values.company_name,
        ico: values.ico,
        dic: values.dic,
        ic_dph: values.ic_dph,
      },
      organization_select: values.organization_select,
    };

    if (
      cart.dpd_delivery_point_id ||
      cart.shop_personal_pickup_id ||
      cart.packeta_delivery_point_id
    ) {
      newCart = {
        ...newCart,
        use_delivery_address: true,
        delivery_address: cart.delivery_address,
      };
    }

    return newCart;
  };

  /*
  const onChange = async ({ values }) => {
    if (JSON.stringify(props.lastValues) !== JSON.stringify(values)) {
      props.dispatch(setLastValues(values));
      const newCart = getNewCart(values);
      updateCartDebounce(dispatch, newCart);
    }
  };
  */

  const onSubmit = async (values) => {
    if (isLoading) {
      return;
    }

    try {
      updateCartDebounceCancel();
      setIsLoading(true);
      const newCart = {
        ...getNewCart(values),
        check_stock_counts: true,
      };
      const updatedCart = await dispatch(updateCart(newCart));

      if (updatedCart.problems.length === 0) {
        await dispatch(createOrder(updatedCart));
      } else {
        setShowErrors(true);
        setProblems(getProblems(updatedCart));
        scrollToTop();
      }
    } catch (e) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  const getProblems = (updatedCart: any = undefined) => {
    let xproblems: any[] = [];

    (updatedCart?.problems || cart.problems).forEach((problem) => {
      delete problem.type;
      delete problem.step;
      const tempProblems = flatten(problem);
      xproblems = xproblems.concat(Object.values(tempProblems));
    });

    return xproblems;
  };

  const onModalContinueButtonClick = async (e) => {
    setMdCookie();
    onContinueButtonClick(e);
    setIsGiftModalVisible(false);
  };

  const onModalCloseButtonClick = (e) => {
    setMdCookie();
    setIsGiftModalVisible(false);
  };

  const setMdCookie = () => {
    cookie.save(modalGiftCookieString, true, {
      path: '/',
      maxAge: 60 * 60 * 24 * 7, // 7 days
    });
  };

  const onContinueButtonClick = async (e) => {
    const mdCookie = cookie.load(modalGiftCookieString);
    const remain = getGiftCartRemain(cart);

    if (
      remain <= 0 &&
      isGiftsVisible &&
      cart &&
      (!cart.chosen_gift || !cart.chosen_gift.product_id) &&
      !mdCookie
    ) {
      setIsGiftModalVisible(true);
    } else {
      let newCart = {};

      if (isDemand) {
        newCart = {
          ...cart,
          step: 3,
          check_stock_counts: false, // Povodne malo byt true, nie som si isty ci moze byt false v pripade dopytu
        };
      } else {
        newCart = {
          ...cart,
          step: 2,
          check_stock_counts: true,
        };
      }

      setNextStepLoading(true);
      const updatedCart = await dispatch(updateCart(newCart));
      setNextStepLoading(false);

      if (!hasCartAvailabilityProblems(updatedCart)) {
        isDemand
          ? props.dispatch(push('/udaje-pre-dorucenie'))
          : props.dispatch(push('/doprava-a-platba'));
      } else {
        scrollToTop();
      }
    }
  };

  const onVoucherApplied = () => {
    scrollToTop();
  };

  const isDiscount =
    cart.customer_card_discount > 0 ||
    (cart.giftcards && cart.giftcards.length);

  return (
    <React.Fragment>
      {cart?.problems?.length > 0 ? (
        <AlertAvailability cart={cart} type={AlertAvailabilityType.CART} />
      ) : (
        ''
      )}

      {cart1Upsell && cart1Upsell.type === 'PRODUCT_CART_FIRST_STEP_UP' ? (
        <UpsellSlider
          title={__('Bonusové tipy')}
          upsell={cart1Upsell}
          ignoreInCartProducts={true}
          cart={cart}
          showModal={false}
        />
      ) : (
        <></>
      )}

      {cart1Upsell && cart1Upsell.type === 'PRODUCT_CART_FIRST_STEP_HIDDEN' ? (
        <HiddenDiv>
          <UpsellSlider
            title={__('Bonusové tipy')}
            upsell={cart1Upsell}
            ignoreInCartProducts={true}
            cart={cart}
            showModal={false}
          />
        </HiddenDiv>
      ) : (
        <></>
      )}

      {error && error.details && error.details.description ? (
        <AlertWrapper>
          <Alert type="error"> {error.details.description} </Alert>
        </AlertWrapper>
      ) : (
        ''
      )}

      {regularCartItems.length ? (
        <CartProductList
          items={regularCartItems}
          dispatch={dispatch}
          user={user}
          cart={cart}
        />
      ) : (
        ''
      )}
      {onDemandCartItems.length ? (
        <CartProductOnDemandList
          items={onDemandCartItems}
          dispatch={dispatch}
          user={user}
          isOnlyDemand={isDemand}
          cart={cart}
        />
      ) : (
        ''
      )}

      {regularCartItems.length === 0 && onDemandCartItems.length === 0 && (
        <EmptyCartAlertWrapper>
          <Alert type="info"> {emptyCartTitleMsg()} </Alert>
        </EmptyCartAlertWrapper>
      )}

      {!isDemand === true ? (
        <>
          <Row>
            {isGiftsVisible ? (
              <Col className={'full-width'}>
                <CartGift
                  dispatch={dispatch}
                  cart={cart}
                  eshopGifts={eshopGifts}
                />
              </Col>
            ) : (
              ''
            )}
            <Col>
              <CartShippingRange
                cart={cart}
                user={user}
                freeShippingPrice={deliveryFreePrice}
              />
            </Col>
            <Col>
              <CartVoucherForm
                dispatch={dispatch}
                onSubmitted={onVoucherApplied}
                render={isPriceRangeRetail(user)}
              />
            </Col>
          </Row>

          {cart &&
          ((cart.giftcards && cart.giftcards.length) ||
            !!cart.customer_card_discount) ? (
            <Row>
              <Col>
                <CartVoucherApplied cart={cart} />
              </Col>
            </Row>
          ) : (
            ''
          )}

          <TotalPriceCart>
            {cart.discount_save > 0 ? (
              <TotalPriceRow className={'discount'}>
                <TotalPriceText>
                  {__('Ušetrili ste')}
                  {!showWithVat ? ' ' + __('(s DPH)') : ''}
                </TotalPriceText>
                <PriceCartWithoutVat>
                  {formatPrice(cart.discount_save, 'EUR')}
                </PriceCartWithoutVat>
              </TotalPriceRow>
            ) : (
              ''
            )}
            {showWithVat ? (
              <>
                {company ? (
                  <TotalPriceRow>
                    <TotalPriceText>{__('Celkom bez DPH')}</TotalPriceText>
                    <PriceCartWithoutVat>
                      {formatPrice(
                        cart.total_items_price_with_discount_without_vat || 0,
                        'EUR',
                      )}
                    </PriceCartWithoutVat>
                  </TotalPriceRow>
                ) : (
                  ''
                )}
                <TotalPriceRow>
                  <TotalPriceText>{__('Celkom s DPH')}</TotalPriceText>
                  <PriceCart>
                    {formatPrice(
                      cart.total_items_price_with_discount || 0,
                      'EUR',
                    )}
                  </PriceCart>
                </TotalPriceRow>
              </>
            ) : (
              <>
                {company ? (
                  <TotalPriceRow>
                    <TotalPriceText>{__('Celkom bez DPH')}</TotalPriceText>
                    <PriceCart>
                      {formatPrice(
                        isDiscount > 0
                          ? cart.total_items_price_with_discount_without_vat
                          : cart.total_items_price_without_vat || 0,
                        'EUR',
                      )}
                    </PriceCart>
                  </TotalPriceRow>
                ) : (
                  ''
                )}
                <TotalPriceRow>
                  <TotalPriceText>{__('Celkom s DPH')}</TotalPriceText>
                  <PriceCartWithoutVat>
                    {formatPrice(
                      isDiscount > 0
                        ? cart.total_items_price_with_discount
                        : cart.total_items_price || 0,
                      'EUR',
                    )}
                  </PriceCartWithoutVat>
                </TotalPriceRow>
              </>
            )}
            {onDemandCartItems.length ? (
              <ProductOnDemandInfoText>
                {__('Cena celkom nezahŕňa produkty na dopyt')}
              </ProductOnDemandInfoText>
            ) : (
              ''
            )}
          </TotalPriceCart>
        </>
      ) : (
        <></>
      )}

      {cart1Upsell && cart1Upsell.type === 'PRODUCT_CART_FIRST_STEP_DOWN' ? (
        <UpsellSlider
          title={__('Bonusové tipy')}
          upsell={cart1Upsell}
          ignoreInCartProducts={true}
          cart={cart}
          showModal={false}
        />
      ) : (
        <></>
      )}

      {isDemand && (
        <Form
          onSubmit={onSubmit}
          // tslint:disable-next-line:jsx-no-lambda
          render={({ handleSubmit }) => (
            <LocalFormCart onSubmit={handleSubmit}>
              {isLoading && (
                <LoaderOverlay>
                  <Loader
                    loading={true}
                    style={{ position: 'fixed', top: '50%', left: '50%' }}
                  ></Loader>
                </LoaderOverlay>
              )}

              {problems.length > 0 && showErrors
                ? problems
                    .filter((p) => !isProblemTypeAvailability(p))
                    .map((problem, i) => {
                      return (
                        <AlertStyled key={i} type="error">
                          {problem}
                        </AlertStyled>
                      );
                    })
                : ''}
              <Row>
                <Col>
                  <Wrapper>
                    <Heading className="in-content">
                      {__('Vaše kontaktné údaje')}
                    </Heading>
                    <FormWrapper>
                      <CartFormRow>
                        <Field
                          name="person_name"
                          validate={required}
                          defaultValue={
                            cart.billing_address.person.name
                              ? cart.billing_address.person.name
                              : !cart.dpd_delivery_point_id &&
                                !cart.shop_personal_pickup_id &&
                                !cart.packeta_delivery_point_id
                              ? cart.delivery_address.person.name
                              : ''
                          }
                        >
                          {({ input, meta }) => (
                            <LocalCartFormCol>
                              <CartLabel htmlFor="forename">
                                {__('Meno')}
                              </CartLabel>
                              <CartInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup className={'error'}>
                                  {meta.error}
                                </ErrorPopup>
                              )}
                            </LocalCartFormCol>
                          )}
                        </Field>
                        <Field
                          name="person_surname"
                          validate={required}
                          defaultValue={
                            cart.billing_address.person.surname
                              ? cart.billing_address.person.surname
                              : cart.delivery_address.person.surname
                          }
                        >
                          {({ input, meta }) => (
                            <LocalCartFormCol>
                              <CartLabel htmlFor="surname">
                                {__('Priezvisko')}
                              </CartLabel>
                              <CartInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup className={'error'}>
                                  {meta.error}
                                </ErrorPopup>
                              )}
                            </LocalCartFormCol>
                          )}
                        </Field>
                        <Field
                          name="person_phone"
                          validate={composeValidators(
                            required,
                            phone,
                            phoneMobile,
                          )}
                          defaultValue={
                            cart.billing_address.person.phone
                              ? cart.billing_address.person.phone
                              : !cart.dpd_delivery_point_id &&
                                !cart.shop_personal_pickup_id &&
                                !cart.packeta_delivery_point_id
                              ? cart.delivery_address.person.phone
                              : ''
                          }
                        >
                          {({ input, meta }) => (
                            <LocalCartFormCol>
                              <CartLabel htmlFor="phone">
                                {__('Mobilné číslo')}
                              </CartLabel>
                              <CartInput
                                {...input}
                                type="tel"
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup className={'error'}>
                                  {meta.error}
                                </ErrorPopup>
                              )}
                            </LocalCartFormCol>
                          )}
                        </Field>
                      </CartFormRow>
                      <CartFormRow>
                        <Field
                          name="person_email"
                          validate={composeValidators(required, email)}
                          defaultValue={
                            cart.billing_address.person.email
                              ? cart.billing_address.person.email
                              : !cart.dpd_delivery_point_id &&
                                !cart.shop_personal_pickup_id &&
                                !cart.packeta_delivery_point_id &&
                                cart.delivery_address.person.email
                              ? cart.delivery_address.person.email
                              : userEmail
                          }
                        >
                          {({ input, meta }) => (
                            <LocalCartFormCol>
                              <CartLabel htmlFor="person_email">
                                {__('E-mail')}
                              </CartLabel>
                              <EmailAutocomplete
                                {...input}
                                type={'text'}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  input.onChange(e);
                                  if (
                                    !isEmailValid(inputValue) ||
                                    (user &&
                                      user.skvelko_type !==
                                        ChematCustomerType.RETAIL) ||
                                    cart.giftcards.length > 0 ||
                                    cart.customer_card_discount ||
                                    cart.customer_card
                                  ) {
                                    return;
                                  }
                                  recognizeCustomerCardDebounce(
                                    dispatch,
                                    inputValue,
                                  );
                                }}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup className={'error'}>
                                  {meta.error}
                                </ErrorPopup>
                              )}
                            </LocalCartFormCol>
                          )}
                        </Field>
                        {!props.user || isRetail(props.user) ? (
                          <>
                            <Field
                              name="organization_select"
                              validate={requiredSelect}
                              component="select"
                              defaultValue={-1}
                            >
                              {({ input, meta }) => (
                                <LocalCartFormCol>
                                  <CartLabel htmlFor="organization_select">
                                    {__('Kalkulácia ceny pre')}
                                  </CartLabel>
                                  <CartSelect
                                    {...input}
                                    onChange={(e) => {
                                      setOrganizationSelectValue(
                                        e.target.value,
                                      );
                                      input.onChange(e);
                                    }}
                                  >
                                    <option key={1} value={-1}>
                                      {__('Zvoľte')}
                                    </option>
                                    <option key={2} value={1}>
                                      {__('Súkromná osoba')}
                                    </option>
                                    <option key={3} value={2}>
                                      {__('Firma / organizácia')}
                                    </option>
                                    <option key={4} value={3}>
                                      {__('Školské zariadenie')}
                                    </option>
                                  </CartSelect>
                                  {meta.error && meta.touched && (
                                    <ErrorPopup className={'error'}>
                                      {meta.error}
                                    </ErrorPopup>
                                  )}
                                </LocalCartFormCol>
                              )}
                            </Field>
                            {organizationSelectValue == 2 ||
                            organizationSelectValue == 3 ? (
                              <Field
                                name="company_name"
                                defaultValue={cart.company.name}
                                validate={required}
                              >
                                {({ input, meta }) => (
                                  <LocalCartFormCol>
                                    <CartLabel htmlFor="company_name">
                                      {__('Názov')}
                                    </CartLabel>
                                    <CartInput
                                      {...input}
                                      error={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched && (
                                      <ErrorPopup className={'error'}>
                                        {meta.error}
                                      </ErrorPopup>
                                    )}
                                  </LocalCartFormCol>
                                )}
                              </Field>
                            ) : (
                              <></>
                            )}{' '}
                          </>
                        ) : (
                          <></>
                        )}
                      </CartFormRow>

                      {!props.user ? (
                        <div>
                          <Field
                            name="company_newsletter_accept"
                            type="checkbox"
                          >
                            {({ input, meta }) => (
                              <CheckboxStyled
                                {...input}
                                value="1"
                                id="company_newsletter_accept"
                              >
                                {__(
                                  'Chcem dostávať emailom informácie o novinkách a výhodných ponukách',
                                )}
                              </CheckboxStyled>
                            )}
                          </Field>
                        </div>
                      ) : (
                        <></>
                      )}

                      {showNote && (
                        <CartFormRow>
                          <Field
                            name="note"
                            defaultValue={cart.note}
                            type="textarea"
                          >
                            {({ input, meta }) => (
                              <CartFormCol className="full-width">
                                <CartLabel htmlFor="note">
                                  {isDemand
                                    ? __('Poznámka k cenovej kalkulácií')
                                    : __('Poznámka k objednávke')}
                                </CartLabel>
                                <CartTextarea rows={3} {...input} />
                                {meta.error && meta.touched && (
                                  <ErrorPopup className={'error'}>
                                    {meta.error}
                                  </ErrorPopup>
                                )}
                              </CartFormCol>
                            )}
                          </Field>
                        </CartFormRow>
                      )}

                      <div>
                        <Field name="show_note" type="checkbox">
                          {({ input, meta }) => (
                            <CheckboxStyled
                              {...input}
                              value="1"
                              id="show_note"
                              checked={showNote}
                              onChange={onShowNoteValuesChange}
                            >
                              {__('Pridať poznámku k cenovej kalkulácií')}
                            </CheckboxStyled>
                          )}
                        </Field>
                      </div>
                      <div>
                        <Field
                          name="terms_accept"
                          validate={required}
                          defaultValue={cart.terms_accept}
                          type="checkbox"
                        >
                          {({ input, meta }) => (
                            <>
                              <CheckboxStyled
                                {...input}
                                value="1"
                                id="terms_accept"
                              >
                                {__(
                                  'Oboznámil som sa so zásadami spracúvania a ochrany',
                                )}{' '}
                                <a onClick={openGdprModal.bind(null, dispatch)}>
                                  {__('osobných údajov')}
                                </a>
                                .
                              </CheckboxStyled>
                              {meta.error && meta.touched && (
                                <ErrorPopup className={'error'}>
                                  {meta.error}
                                </ErrorPopup>
                              )}
                            </>
                          )}
                        </Field>
                      </div>
                    </FormWrapper>
                  </Wrapper>
                </Col>
              </Row>
              <Footer>
                <Loader loading={nextStepLoading}>
                  <NextButton
                    className="primary lg"
                    disabled={(cart?.items || []).length === 0}
                    type="submit"
                  >
                    {__('Nezáväzná kalkulácia ceny')}
                  </NextButton>
                </Loader>
                <BackLink
                  to={prevPath ? getBaseUrl(prevPath) : '/'}
                  className="lg"
                >
                  <BackIcon icon="chevron" />
                  {__('Pridať další produkt')}
                </BackLink>
              </Footer>
            </LocalFormCart>
          )}
        />
      )}

      {!isDemand && (
        <Footer>
          <Loader loading={nextStepLoading}>
            <NextButton
              className="primary lg"
              onClick={onContinueButtonClick}
              disabled={(cart?.items || []).length === 0}
            >
              {__('Výber dopravy a platby')}
            </NextButton>
          </Loader>
          <BackLink to={prevPath ? getBaseUrl(prevPath) : '/'} className="lg">
            <BackIcon icon="chevron" />
            {__('Pokračovať v nakupovaní')}
          </BackLink>
        </Footer>
      )}

      {isGiftModalVisible ? (
        <CartGiftModal
          eshopGifts={eshopGifts}
          cart={cart}
          title="Máte nárok na darček k nákupu"
          dispatch={dispatch}
          onContinueButtonClick={onModalContinueButtonClick}
          onCloseButtonClick={onModalCloseButtonClick}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

const AlertStyled = styled(Alert)`
  margin: ${rem(10)} 0;
`;

const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #7d7d7da8;
`;

export const Heading = styled(Heading2)`
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 0px;
`;

const CartTextarea = styled(TextareaStyledBlock)``;

const LocalCol = styled.div`
  float: left;
  min-height: ${rem(1)};
  width: 100%;
  padding-right: ${rem(grid.padding / 2)};
  padding-left: ${rem(grid.padding / 2)};
`;

const LocalCartFormCol = styled(LocalCol)`
  padding-right: ${rem(10)};
  padding-left: ${rem(10)};
  ${({ theme }) => theme.media('xs')} {
    width: ${({ theme }) => theme.grid.col.col4};
    &.full-width {
      width: ${({ theme }) => theme.grid.col.col12};
    }
  }
`;

const FormWrapper = styled.div`
  /*max-width: ${rem(600)};*/
`;

const LocalFormCart = styled.form``;

const Row = styled.div`
  margin-right: ${({ theme }) => rem(-theme.grid.padding / 2)};
  margin-left: ${({ theme }) => rem(-theme.grid.padding / 2)};
  ${({ theme }) => theme.media('sm')} {
    margin-right: ${({ theme }) => rem(-theme.grid.padding / 4)};
    margin-left: ${({ theme }) => rem(-theme.grid.padding / 4)};
    display: flex;
    flex-wrap: wrap;
  }
`;

const Col = styled.div`
  flex: 1 0 50%;
  padding-bottom: 0;
  &.full-width {
    flex: 1 0 100%;
  }
  ${({ theme }) => theme.media('sm')} {
    padding-right: ${({ theme }) => rem(theme.grid.padding / 4)};
    padding-left: ${({ theme }) => rem(theme.grid.padding / 4)};
    padding-bottom: 10px;
  }
`;

export const TotalPriceCart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: ${({ theme }) => theme.color.white};
  padding: ${rem(20)};
  margin: ${rem(2)} ${({ theme }) => rem(-theme.grid.padding / 2)} ${rem(30)};
  ${({ theme }) => theme.media('sm')} {
    background: transparent;
    padding-top: ${rem(20)};
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const TotalPriceRow = styled.div`
  display: flex;
  align-items: baseline;

  &.discount {
    color: ${({ theme }) => theme.color.success};
  }
`;

export const TotalPriceText = styled.div`
  font-size: ${rem(14)};
  margin-right: ${rem(10)};
  ${({ theme }) => theme.media('sm')} {
    margin-right: ${rem(20)};
  }
`;

const ProductOnDemandInfoText = styled.p`
  margin-top: 12px;
  margin-bottom: 12px;
  color: #4d94ff;
  font-weight: bold;
`;

export const PriceCart = styled.div`
  font-size: ${rem(24)};
  font-weight: 700;
`;

export const PriceCartWithoutVat = styled.div`
  font-size: ${rem(20)};
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  margin: ${rem(30)} 0;
  ${({ theme }) => theme.media('xs')} {
    align-items: center;
  }
  ${({ theme }) => theme.media('sm')} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const NextButton = styled(Button)`
  ${ButtonStyledStyles};
  &.lg {
    padding-right: ${rem(20)};
    padding-left: ${rem(20)};
  }
`;

export const BackLinkStyles = css`
  background: transparent;
  margin-top: ${rem(20)};
  &:not(:disabled):hover,
  &:focus {
    background: ${({ theme }) => theme.color.white};
    border-color: ${({ theme }) => theme.color.white};
  }
  ${({ theme }) => theme.media('sm')} {
    order: -1;
    margin-top: 0;
  }
`;

export const BackIcon = styled(Icon)`
  ${({ theme }) => theme.size(12)};
  margin-right: ${rem(16)};
  transform: translateY(${rem(-2)}) rotate(90deg);
`;

export const BackLink = styled(Link)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  ${BackLinkStyles};
`;

export const AlertWrapper = styled.div`
  margin-top: ${rem(20)};
`;

export const EmptyCartAlertWrapper = styled.div`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};
`;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  padding: ${rem(10)} ${rem(20)};
  margin-top: ${rem(2)};
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  font-size: ${rem(14)};

  ${({ theme }) => theme.media('sm')} {
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    margin-top: 0;
    margin-bottom: ${rem(15)};
  }
`;

const CartSelect = styled(SelectStyled)`
  width: 100%;
  ${(props: any) =>
    props.disabled
      ? 'border-color: #eeeeee; color: #b1b1b1 !important; background: white !important;'
      : ''}
`;

const HiddenDiv = styled.div`
  display: none;
`;

const mapStateToProps = (state) => {
  return {
    error: state.cart.error,
    prevPath: state.general.prevPath,
    deliveryFreePrice: state.general.deliveryFreePrice,
    eshopGifts: state.cart.eshopGifts,
    settings: state.general.settings,
    lastValues: state.cart.lastValues,
    cart1Upsell: state.cart.cart1Upsell,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Cart1' })(Cart1),
);
