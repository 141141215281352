import * as React from 'react';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import ImagePlaceholder from '../Image/ImagePlaceholder';
import {
  formatPrice,
  getBaseUrl,
  getProductPrices,
  getStickedSaleText,
  isDiscountInCart,
  isOutOfStock,
  prop,
} from '../../../utilities';
import { ButtonIcon, ButtonStyles } from '../form/Button';
import Availability from './Availability';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { addItemToCart } from '../../../containers/Cart/actions';
import {
  getOnStockCount,
  setAddToCartMaxItemExceededModalProduct,
  setAddToCartModalProduct,
  setAddToCartModalProductOutOfStock,
  setSelectVariantModal,
} from '../../../containers/App/actions';
import { hasProductVariants } from '../../../containers/Product/actions';
import { __ } from 'react-i18n/lib';
import { Loader } from '../Loader/Loader';
import { useState } from 'react';

const Wrapper = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.color.white};
  position: relative;
`;

const WrapperContent = styled.div`
  padding: ${rem(5)} ${rem(15)};
`;

const Anchor = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const Thumb = styled(ImagePlaceholder)`
  display: block;
  width: ${rem(120)};
  padding: 0;
  margin: auto;
`;

export const ProductPreviewTitle = styled.p`
  font-size: ${rem(14)};
  line-height: 1.5;
  margin: 0;
`;

const Title = styled(ProductPreviewTitle)`
  ${ProductPreviewTitle};
  margin: ${rem(10)} 0 ${rem(15)};
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: ${rem(14 * 1.5 * 2)};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PreviewAvailability = styled(Availability)`
  font-size: ${rem(12)};
`;

const FormRow = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: row;
  &.with-button {
    padding-right: ${rem(60)};
  }

  ${({ theme }) => theme.media('xs')} {
    flex-direction: row-reverse;
  }
`;

const AvailPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Price = styled.span`
  font-weight: 700;
`;

const PriceNew = styled.span`
  font-size: ${rem(14)};
  font-weight: 400;
  text-decoration: line-through;
  line-height: ${rem(16)};
`;

const PriceSale = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.color.primary};
  line-height: ${rem(16)};
`;

const PriceDiscount = styled(PriceSale)`
  color: ${({ theme }) => theme.color.success};
`;

const Sticker = css`
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  line-height: ${rem(15)};
  width: ${rem(60)};
  height: ${rem(60)};
  position: absolute;
  top: ${rem(10)};
  right: ${rem(10)};
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.media('xs')} {
    width: ${rem(40)};
    height: ${rem(40)};
    top: ${rem(5)};
    right: ${rem(5)};
    line-height: ${rem(10)};
    font-size: ${rem(9)};
  }
`;

const SaleSticker = styled.div`
  ${Sticker};
  background: ${({ theme }) => theme.color.primary};
`;

const NewsSticker = styled.div`
  ${Sticker};
  background: #406df3;
`;

const CustomerPriceSticker = styled.div`
  ${Sticker};
  padding: ${rem(10)};
  line-height: 1.1;
  font-size: ${rem(13)};
  background: ${({ theme }) => theme.color.green};
`;

const AddToCartWrapper = styled.div``;

export const AddToCart = styled.button<{ smallShowCart: boolean }>`
  ${ButtonStyles};
  background: ${({ theme }) => theme.color.cartButtonBackground};
  border-radius: 50%;
  line-height: 1;
  padding: ${rem(12)};
  ${({ theme }) => theme.transition('background-color')};
  outline: none;
  position: absolute;
  right: ${rem(10)};
  bottom: ${rem(10)};
  &:focus,
  &:not(:disabled):hover {
    background: ${({ theme }) => theme.color.primary};
  }

  ${({ theme }) => theme.media('xs')} {
    right: ${rem(80)};
  }
`;

export const AddToCartIcon = styled(ButtonIcon)<{ smallShowCart: boolean }>`
  ${ButtonStyles};
  margin: 0;
  ${({ theme, smallShowCart }) =>
    smallShowCart ? theme.size(24) : theme.size(32)};
  ${({ theme }) => theme.transition('fill')};
  ${AddToCart}:focus &,
  ${AddToCart}:not(:disabled):hover & {
    fill: ${({ theme }) => theme.color.white};
  }
`;

const RotateImageWrapper = styled.div`
  position: absolute;
  right: 10px;
  bottom: 70px;
  width: ${rem(40)};
  height: ${rem(40)};
`;

const ImageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media('xs')} {
    flex-direction: row;
  }
`;

const ThumbWrapper = styled.div`
  position: relative;
  display: flex;
`;

interface ProductPreviewProps {
  product: any;
  upsellId: number;
  dispatch: any;
  user: any;
  cart?: any;
  small?: boolean;
  smallShowCart?: boolean;
  notLazyloading?: boolean;
  fromSkvelkoSet?: boolean;
  fromSchoolWebCategory: boolean;
  setLoading?: any;
  showModal?: boolean;
}

const UpsellProductPreview = (props: ProductPreviewProps) => {
  const [loading, setLoading] = useState(false);
  const { fromSkvelkoSet, showModal } = props;
  const fromSchoolWebCategory = prop(props, 'fromSchoolWebCategory', false);

  const addToCart = async () => {
    if (getOnStockCount(props.cart, props.product) < 1) {
      props.dispatch(
        setAddToCartModalProductOutOfStock({ product: props.product }),
      );
      return;
    }

    if (hasProductVariants(product)) {
      props.dispatch(setSelectVariantModal(props.product));
      return;
    }

    const goodId = props.product.main_good.good_id;
    const quantityLimit = prop(props.product, 'main_good.order_quantity_limit');
    const itemInCart = cart.items.find(
      (item) => prop(item, 'good.good_id') === goodId,
    );
    const countInCart = itemInCart && itemInCart.count ? itemInCart.count : 0;
    const count = quantityLimit ? quantityLimit + countInCart : 1;

    if (props.setLoading) {
      props.setLoading(true);
    } else {
      setLoading(true);
    }

    try {
      await props.dispatch(
        addItemToCart(
          props.product.main_good.product_id,
          goodId,
          count,
          undefined,
          undefined,
          undefined,
          undefined,
          props.upsellId,
        ),
      );
      if (showModal) {
        props.dispatch(
          setAddToCartModalProduct({ product: props.product, count }),
        );
      }
    } catch (e: any) {
      if (e?.details?.name === 'MAX_ITEMS_EXCEEDED') {
        await props.dispatch(setAddToCartMaxItemExceededModalProduct(true));
      }
    } finally {
      if (props.setLoading) {
        props.setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const { product, user, cart, small, smallShowCart, notLazyloading } = props;
  const placeholder = '/images/placeholder-200.png';
  const placeholder2x = '/images/placeholder-200@2x.png';
  const onDemand = product?.on_demand;

  const {
    currencyId,
    price,
    retailPrice,
    oldPrice,
    discountPrice,
    isSale,
    isCustomerPrice,
    isWarehouse,
  } = getProductPrices(product, product?.main_good, cart, user);

  let isNew = false;
  if (
    !isSale &&
    product.is_new &&
    (!product.isnew_valid || new Date(product.isnew_valid) > new Date())
  ) {
    isNew = true;
  }

  let is3D = false;
  if (product && product.publish && product.publish.length) {
    const skPublish =
      product.publish.length === 1
        ? product.publish[0]
        : product.publish.find((p) => p.lang_id === 'sk');
    if (skPublish) {
      const description = prop(skPublish, 'content.json_content.body', null);
      if (
        description &&
        typeof description === 'string' &&
        description.includes('[3dmodel')
      ) {
        is3D = true;
      }
    }
  }

  return (
    <Loader loading={loading} style={{ width: '100%' }}>
      <Wrapper className={'product'}>
        {/* Don't remove product class! */}
        <Anchor
          className={'url'}
          to={getBaseUrl(product.url)}
          title={product.name}
        >
          <ImageContentWrapper>
            <ThumbWrapper>
              {/* Don't remove url class! */}

              {product?.main_good?.price_create_type === 1 ? (
                <></>
              ) : (
                <>
                  {isSale && !fromSkvelkoSet ? (
                    <SaleSticker>{getStickedSaleText(product)}</SaleSticker>
                  ) : (
                    ''
                  )}
                  {isNew ? <NewsSticker>{__('NOVÉ')}</NewsSticker> : ''}
                  {isCustomerPrice && !fromSkvelkoSet ? (
                    <CustomerPriceSticker>
                      {__('Cena pre vás')}
                    </CustomerPriceSticker>
                  ) : (
                    ''
                  )}
                </>
              )}
              <Thumb
                src={product.picture}
                placeholder={placeholder}
                retinaPlaceholder={placeholder2x}
                w={100}
                h={100}
                notLazyloading={notLazyloading}
              ></Thumb>
            </ThumbWrapper>

            {is3D ? (
              <RotateImageWrapper>
                <img src="/images/3D.svg" alt={__('3D ikona')} />
              </RotateImageWrapper>
            ) : (
              <></>
            )}

            <WrapperContent>
              <Title className={'title'}>{product.name}</Title>
              {/* Don't remove title class! */}
              <FormRow>
                {!fromSkvelkoSet && !fromSchoolWebCategory && !onDemand && (
                  <AvailPrice>
                    {!small ? <PreviewAvailability product={product} /> : ''}
                    {product?.main_good?.price_create_type === 1 ? (
                      <div>
                        <div>
                          <div>
                            <PriceNew>
                              {formatPrice(
                                product.main_good.price_without_vat_sale,
                                currencyId,
                              )}
                            </PriceNew>
                          </div>
                          <div>
                            <PriceDiscount className={'price'}>
                              {/* Don't remove price class! */}
                              {formatPrice(
                                product.main_good.price_without_vat,
                                currencyId,
                              )}
                            </PriceDiscount>
                          </div>
                        </div>
                      </div>
                    ) : isSale ? (
                      <div>
                        <div>
                          <PriceNew>
                            {formatPrice(oldPrice, currencyId)}
                          </PriceNew>
                        </div>
                        <div>
                          <PriceSale className={'price'}>
                            {/* Don't remove price class! */}
                            {formatPrice(price, currencyId)}
                          </PriceSale>
                        </div>
                      </div>
                    ) : isDiscountInCart(cart, user) && discountPrice ? (
                      <div>
                        <div>
                          <PriceNew>{formatPrice(price, currencyId)}</PriceNew>
                        </div>
                        <div>
                          <PriceDiscount className={'price'}>
                            {/* Don't remove price class! */}
                            {formatPrice(discountPrice, currencyId)}
                          </PriceDiscount>
                        </div>
                      </div>
                    ) : isWarehouse && retailPrice ? (
                      <div>
                        <div>
                          <PriceNew>
                            {formatPrice(retailPrice, currencyId)}
                          </PriceNew>
                        </div>
                        <div>
                          <PriceDiscount className={'price'}>
                            {/* Don't remove price class! */}
                            {formatPrice(price, currencyId)}
                          </PriceDiscount>
                        </div>
                      </div>
                    ) : (
                      <>
                        <Price className={'price'}>
                          {formatPrice(price, currencyId)}
                        </Price>
                        {/* Don't remove price class! */}
                      </>
                    )}
                  </AvailPrice>
                )}
              </FormRow>
            </WrapperContent>
          </ImageContentWrapper>
        </Anchor>
        {(!small || smallShowCart) &&
        !fromSchoolWebCategory &&
        !onDemand &&
        !isOutOfStock(product) ? (
          <AddToCartWrapper>
            <AddToCart
              className={'add-to-cart'}
              onClick={addToCart}
              aria-label={__('Pridať do košíka')}
              smallShowCart={smallShowCart}
            >
              {/* Don't remove add-to-cart class! */}
              <AddToCartIcon icon="cart2" smallShowCart={smallShowCart} />
            </AddToCart>
          </AddToCartWrapper>
        ) : (
          ''
        )}
      </Wrapper>
    </Loader>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    cart: state.cart.cart,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'UpsellProductPreview' })(UpsellProductPreview),
);
