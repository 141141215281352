import * as React from 'react';
import { __ } from 'react-i18n';
import { getSchoolNameFromSkvelkoSet, getUrl } from '../../../../utilities';
import { rem } from 'polished';
import ImagePlaceholder from '../../../_helpers/Image/ImagePlaceholder';
import { ThumbStyles } from '../../../_helpers/product/product-styles';
import {
  Button,
  ButtonStyledStyles,
  ButtonStyles,
} from '../../../_helpers/form/Button';
import styled from 'styled-components';
import { MAX_FOUNDED_QUERIES, SearchResultProps } from './constants';
import { DropdownStyles } from '../../../Dropdown/Dropdown';
import { Link } from 'react-router';
import { Loader } from '../../../_helpers/Loader/Loader';
import ProductPrice from './ProductPrice';
import SkvelkoSetPrice from './SkvelkoSetPrice';
import FoundedQueries from './FoundedQueries';
import {
  concatSearchProductsAndSkvelkoSets,
  SearchProductType,
} from '../../../../utilities/product';
import CategoryList from './CategoryList';
import { MoreButton } from './styles';

const DropdownWidth = 768;

const Dropdown = styled.div`
  ${DropdownStyles};
  font-size: ${rem(14)};
  top: 120%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, 0);
  z-index: 1;
  height: auto;
  &.visible {
    display: block;
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: ${rem(15)} ${rem(30)};
`;

const Col = styled.div`
  padding: 0 ${rem(15)};
  width: ${rem(DropdownWidth / 2)};
  &:last-child {
    padding-right: 0;
  }
  &:first-child {
    padding-left: 0;
  }
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const SectionTitle = styled.p`
  font-size: ${rem(14)};
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  margin: 0 0 ${rem(10)} 0;
`;

const Item = styled.div`
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(10)};
  ${({ theme }) => theme.transition('color')};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: none;
  }
`;

const Product = styled(Item)``;

const WebContent = styled(Item)``;

const ProductThumb = styled(ImagePlaceholder)`
  ${ThumbStyles};
  width: ${(props) => rem(props.w)};
  height: ${(props) => rem(props.h)};
  padding: 0;
`;

const WebContentThumb = styled(ImagePlaceholder)`
  width: ${(props) => rem(props.w)};
  height: ${(props) => rem(props.h)};
  vertical-align: middle;
`;

const ProductContent = styled.div`
  margin-left: ${rem(16)};
`;

const SkvelkoProductContent = styled(ProductContent)`
  line-height: ${rem(14)};
`;

const ProductName = styled.p`
  width: ${rem(300)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${rem(4)};
`;

const CloseButton = styled(Button)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  padding: ${rem(12)};
  float: left;
  top: ${rem(8)};
  right: ${rem(8)};
  position: absolute;
  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    right: ${rem(2)};
    background: ${({ theme }) => theme.color.white};
    ${({ theme }) => theme.size(20, 2)};
    ${({ theme }) => theme.transition('transform')};
  }
  &:before {
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }
`;

const NotFoundText = styled.p`
  margin: 0 0 ${rem(10)} 0;
`;

const SearchLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: none;
  }
  &.wrap {
    flex-wrap: wrap;
  }
`;

const WebContentName = styled.p`
  padding: 0 0 0 ${rem(16)};
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const CategoryWrapper = styled.div`
  min-height: ${rem(100)};
`;

const DesktopSearchResult = (props: SearchResultProps) => {
  const {
    onRedirect,
    onSubmitSearchInput,
    handleClickOutsideForm,
    searchDropdownVisible,
    searchedQuery,
    searchedResultsLoader,
    user,
    cart,
    searchedResults: {
      categories,
      webContent,
      products,
      demandProducts,
      products: { founded_queries },
    },
  } = props;

  const MAX_PRODUCTS = 5;
  const MAX_WEB_CONTENT = 3;
  const placeholder = '/images/placeholder-46.png';
  const placeholder2x = '/images/placeholder-46@2x.png';

  const webContentCount = webContent?.total;
  const productsCount = products?.total;
  const demandProductsCount = demandProducts?.total;
  const productsFoundedQueries =
    founded_queries?.length && founded_queries?.slice(0, MAX_FOUNDED_QUERIES);
  const demanProductsFoundedQueries =
    demandProducts?.founded_queries?.length &&
    demandProducts?.founded_queries?.slice(0, MAX_FOUNDED_QUERIES);

  const onMoreClick = () => onSubmitSearchInput('0');

  const renderCategories = () => (
    <CategoryWrapper>
      <SectionTitle>{__('Kategórie v eshope')}</SectionTitle>
      {!searchedResultsLoader && categories?.catalog_category?.length ? (
        <CategoryList
          categories={categories}
          searchedQuery={searchedQuery}
          onRedirect={onRedirect}
          onMoreClick={onMoreClick}
        />
      ) : (
        renderNotFound()
      )}
    </CategoryWrapper>
  );

  const renderWebContents = () => (
    <>
      <SectionTitle>{__('Web Daffer')}</SectionTitle>
      {!searchedResultsLoader && webContent?.web_content?.length ? (
        <List>
          {webContent.web_content
            .slice(0, MAX_WEB_CONTENT)
            .map((item, index) => (
              <WebContent key={index}>
                <SearchLink
                  to={getUrl(item.url)}
                  onClick={onRedirect.bind(undefined, item.url)}
                >
                  <WebContentThumb
                    src={
                      item && item.json_content
                        ? item?.json_content?.meta?.og_image
                        : item?.content?.json_content?.meta?.og_image
                    }
                    w={90}
                    h={50}
                    placeholder={placeholder}
                    retinaPlaceholder={placeholder2x}
                    notLazyloading={true}
                  />
                  <WebContentName>{item.name}</WebContentName>
                </SearchLink>
              </WebContent>
            ))}
          {MAX_WEB_CONTENT < webContentCount &&
            renderShowMore(
              webContentCount,
              __('stránok'),
              onSubmitSearchInput.bind(null, '1'),
            )}
        </List>
      ) : (
        renderNotFound()
      )}
    </>
  );

  const renderEshopProducts = () => {
    const items = concatSearchProductsAndSkvelkoSets(
      products.products,
      products.skvelko_sets,
      products.positions,
    );
    return renderProducts(
      items,
      productsCount,
      __('Produkty v eshope'),
      onSubmitSearchInput.bind(null, '0'),
      productsFoundedQueries,
    );
  };

  const renderDemandProducts = () => {
    const items = concatSearchProductsAndSkvelkoSets(
      demandProducts ? demandProducts.products : [],
      [],
      demandProducts ? demandProducts.positions : [],
    );

    return renderProducts(
      items,
      demandProductsCount,
      __('Produkty pre školské zariadenia'),
      onSubmitSearchInput.bind(null, '2'),
      demanProductsFoundedQueries,
    );
  };

  const renderProducts = (
    items,
    productsCount,
    title,
    onSubmit,
    foundedQueries,
  ) => {
    return (
      <>
        <SectionTitle>{title}</SectionTitle>
        <FoundedQueries
          searchedResultsLoader={searchedResultsLoader}
          searchedQuery={searchedQuery}
          foundedQueries={foundedQueries}
        />

        {!searchedResultsLoader && items && items.length ? (
          <List>
            {items.map((item, index) => {
              if (item.type === SearchProductType.SKVELKO_SET) {
                return (
                  <Product key={index}>
                    <SearchLink
                      to={getUrl(`/sada/${item.item.id}`)}
                      onClick={onRedirect.bind(
                        undefined,
                        `/sada/${item.item.id}`,
                        item.item.id,
                      )}
                    >
                      <ProductThumb
                        src={`images/uni_set_${index % 13}.jpg`}
                        w={46}
                        h={46}
                        placeholder={placeholder}
                        retinaPlaceholder={placeholder2x}
                        notLazyloading={true}
                      />
                      <SkvelkoProductContent>
                        {item.item.schoolName && (
                          <ProductName>
                            {getSchoolNameFromSkvelkoSet(item.item)}
                          </ProductName>
                        )}
                        <ProductName>{item.item.fullName} </ProductName>
                        <PriceWrapper>
                          <SkvelkoSetPrice
                            skvelkoSet={item.item}
                            cart={cart}
                            user={user}
                            priceAfterNoColor={true}
                          />
                        </PriceWrapper>
                      </SkvelkoProductContent>
                    </SearchLink>
                  </Product>
                );
              } else if (item.type === SearchProductType.PRODUCT) {
                return (
                  <Product key={index}>
                    <SearchLink
                      to={getUrl(item.item.url)}
                      onClick={onRedirect.bind(undefined, item.item.url)}
                    >
                      <ProductThumb
                        src={item.item.picture}
                        w={46}
                        h={46}
                        placeholder={placeholder}
                        retinaPlaceholder={placeholder2x}
                        notLazyloading={true}
                      />
                      <ProductContent>
                        <ProductName>{item.item.name}</ProductName>
                        <PriceWrapper>
                          <ProductPrice
                            product={item.item}
                            cart={cart}
                            user={user}
                            priceAfterNoColor={true}
                            isOnDemand={item.item.on_demand ? true : false}
                          />
                        </PriceWrapper>
                      </ProductContent>
                    </SearchLink>
                  </Product>
                );
              } else {
                return <> </>;
              }
            })}
            {MAX_PRODUCTS < productsCount &&
              renderShowMore(
                productsCount,
                __('produktov'),
                onSubmit.bind(null, '0'),
              )}
          </List>
        ) : (
          renderNotFound()
        )}
      </>
    );
  };

  const renderShowMore = (count, text, onClick) => (
    <MoreButton onClick={onClick}>
      <strong>{__('Viac')}</strong> ({`${count} ${text}`})
    </MoreButton>
  );

  const renderNotFound = () =>
    !searchedResultsLoader && <NotFoundText>{__('Nenájdené')}</NotFoundText>;

  return (
    <Dropdown className={searchDropdownVisible ? `visible` : ''}>
      <Loader loading={searchedResultsLoader}>
        <CloseButton className="primary" onClick={handleClickOutsideForm} />
        <Wrapper>
          <Col>
            {renderCategories()}
            {renderWebContents()}
          </Col>
          <Col>{renderEshopProducts()}</Col>
          <Col>{renderDemandProducts()}</Col>
        </Wrapper>
      </Loader>
    </Dropdown>
  );
};
export default DesktopSearchResult;
