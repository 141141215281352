import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { useState } from 'react';
import { __ } from 'react-i18n/lib';
import { rem } from 'polished';
import {
  canShowSkvelkoSearch,
  getCartSkvelkoSetSchoolIds,
  isCartCointainsSkvelkoSet,
  isSchoolOrTeacherOrPC2Company,
  loadGlobalMetaTags,
  prop,
} from '../../utilities';
import styled from 'styled-components';
import {
  loadSkvelkoCities,
  setCurrentSet,
  loadSetPopupText,
  loadUniversalSets,
  loadUniversalSetsSpecial,
  loadFilterFromLocation,
  selectSchool,
  resetSkvelko,
  loadWarningRemoveFromSetText,
  scrollToAnchor,
} from '../Home/actions';
import {
  addItemsToCart,
  getSomeSetsHasDeliveryToSchoolActivated,
} from '../Cart/actions';
import ChooseModal, {
  Button2,
} from '../../components/_helpers/Modal/ChooseModal';
import SetAddedToCartModal from '../../components/_helpers/Modal/SetAddedToCartModal';
import { push } from 'react-router-redux';
import MetaTags from '../../components/MetaTags/MetaTags';
import {
  loadAlertSchoolText,
  loadSkvelkoBottomText,
  loadSkvelkoDescriptionText,
  setAddToCartMaxItemExceededModalProduct,
  setShowPopupSetSelect,
  setShowPopupSetSelect2,
} from '../App/actions';
import { ProductVariantSelect } from '../../components/Product/Product';
import {
  getBulkVariantProductsIds,
  hasProductVariants,
} from '../Product/actions';
import { ContainerIndent } from '../../components/_layout/Page/ContentContainer';
import BaseModal from '../../components/_helpers/Modal/BaseModal';
import * as Modal from '../../components/_helpers/Modal/BaseModal';
import Alert from '../../components/Alert/Alert';
import { Link } from 'react-router';
import { Heading2 } from '../../components/_helpers/Heading/Heading';
import SetPreviews from '../../components/_helpers/Sets/SetPreviews';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { ANCHOR_UNIVERSAL_SETS, SETS_UNIQID, URL_SETS } from '../../constants';
import SetsList from './SetsList';
import ProductsSlider from '../../components/_helpers/product/ProductsSlider';
import { CheckboxStyled } from '../../components/_helpers/form/Checkbox';
import SkvelkoSearchBox from './SkvelkoSearchBox';

const SetsWrapper = styled.div`
  ${({ theme }) => theme.media('md')} {
    margin-bottom: ${rem(16)};
  }
`;

const ScrollAnchor = styled.div``;

const Row = styled.div`
  display: flex;
  & + & {
    margin-top: ${rem(15)};
  }
`;

const CloseButton = styled(Button2)``;

const StyledFooterModal = styled(Modal.Footer)`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  ${({ theme }) => theme.media(600)} {
    justify-content: space-between;
  }
`;

const AlertLink = styled(Link)`
  display: block;
  font-weight: bold;
  margin-top: ${rem(10)};
`;

const SetsHeading = styled(Heading2)`
  text-align: center;
  font-size: ${rem(16)};
  margin: ${rem(20)} 0;
  padding: 0 ${rem(8)};
  strong {
    font-weight: 800;
    font-size: ${rem(16)};
  }
  ${({ theme }) => theme.media('sm')} {
    text-align: left;
  }
  ${({ theme }) => theme.media('md')} {
    padding: 0 ${rem(40)};
    font-size: ${rem(20)};
    strong {
      font-size: ${rem(24)};
    }
  }
`;

const ContainerIndentStyled = styled(ContainerIndent)`
  &.padding-bottom {
    ${({ theme }) => theme.media('md')} {
      padding-bottom: ${rem(20)};
    }
  }
`;

const ModalBulkCheckboxWrapper = styled.div`
  display: flex;
  align-self: center;
  padding: ${rem(6)} 0;
  margin-top: ${rem(15)};
`;

export interface SetsProps {
  user: any;
  dispatch: any;
  params?: {
    id?: string;
  };
  skvelkoIsLoading: boolean;
  skvelkoSetsIsLoading: boolean;
  skvelkoCities: any;
  skvelkoSchools: any;
  skvelkoClasses: any;
  skvelkoSets: any;
  skvelkoSetsIsEmpty: boolean;
  skvelkoClassesIsEmpty: boolean;
  skvelkoSet: any;
  universalSets: any[];
  universalSetsSpecial: any[];
  location: any;
  currentFilterTitle: string;
  currentSchoolName: string;

  currentSet: any;
  currentClassId: any;
  cart: any;

  setPopupText?: {
    name: string;
    body: string;
  };
  alertSchoolText?: {
    name: string;
    body: string;
  };
  warningRemoveFromSetText?: {
    name: string;
    body: string;
  };

  metaTags: any;

  clubUserId: number;
  currentPath?: string;
  settings?: any;
}

const Sets = (props: SetsProps) => {
  const [items, setItems] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [variantModalOpen, setVariantModalOpen] = useState<any>(null);
  const [variantModalOpenEvent, setVariantModalOpenEvent] = useState<any>(null);
  const [variantModalError, setVariantModalError] = useState(false);
  const [warningCountId, setWarningCountId] = useState(null);
  const [updateVariantsInBulk, setUpdateVariantsInBulk] = useState(false);

  React.useEffect(() => {
    props.dispatch(setShowPopupSetSelect2(true));
  }, []);

  React.useEffect(() => {
    /*
    if (!isSsr() && props.currentSet?.id) {
      const url = getSetUrl(props.currentSet.id);
      if (props.currentPath !== url) {
        props.dispatch(replace(url));
        props.dispatch(setShowPopupSetSelect2(false));
      }
    }
    */
  }, [props.currentSet]);

  React.useEffect(() => {
    if (props.skvelkoSet) {
      setItems(props.skvelkoSet?.productsWithCount);
    }
  }, [props.skvelkoSet]);

  React.useEffect(() => {
    if (!props.alertSchoolText && isSchoolOrTeacherOrPC2Company(props.user)) {
      props.dispatch(loadAlertSchoolText());
    }
  }, [props.user]);

  React.useEffect(() => {
    if (props.currentPath === URL_SETS) {
      props.dispatch(resetSkvelko());
      props.dispatch(setShowPopupSetSelect(false));
      props.dispatch(setShowPopupSetSelect2(true));
    }
  }, [props.currentPath]);

  React.useEffect(() => {
    if (props.location?.hash === `#${ANCHOR_UNIVERSAL_SETS}`) {
      scrollToAnchor('scroll-anchor');
    }
  }, [props.location]);

  const onItemChange = (i, e, withoutCheck: boolean = false) => {
    setWarningCountId(null);
    const copyItems = items.slice(0);
    if (
      !withoutCheck &&
      parseInt(e, 10) === 0 &&
      copyItems[i]?.product?.set_delete_warning
    ) {
      setWarningCountId(i);
      return;
    }
    copyItems[i].count = parseInt(e, 10);
    setItems(copyItems);
  };

  const onVariationChangeModal = (productIds, attribName, attribValue) => {
    try {
      const attribObj = {};
      attribObj[attribName] = attribValue;

      const value = attribValue;
      const copyItems = items.slice(0);

      for (const productId of productIds) {
        const index = copyItems.findIndex(
          (p) => p && p.product && p.product.product_id === productId,
        );

        copyItems[index].selectedGoodId = value ? Number(value) : null;
        copyItems[index].isError = false;
        copyItems[index].attribObj = attribObj;

        const attribCount = copyItems[index]?.product?.attribs?.length;
        const goods = copyItems[index]?.product.goods;

        let resGood;
        for (const good of goods) {
          if (good && good.web_attribs && good.web_attribs.length > 0) {
            let matchedAttribCount = 0;
            good.web_attribs.forEach((attrib) => {
              const attribName = attrib?.attrib_name;
              const attribValue = attrib?.values[0]?.attrib_value;

              if (attribObj[attribName] == attribValue) {
                matchedAttribCount += 1;
              }
            });
            if (matchedAttribCount == attribCount) {
              resGood = good;
              break;
            }
          }
        }

        if (resGood) {
          copyItems[index].selectedGoodId = resGood.good_id;
        }
      }

      setItems(copyItems);
    } catch (e) {}
  };

  const isSetValid = () => {
    let isValid = true;
    const copyItems = items.slice(0);
    copyItems.map((item) => {
      if (hasProductVariants(item.product) && !item.selectedGoodId) {
        item.isError = true;
        isValid = false;
      }
      return item;
    });
    setItems(copyItems);
    return isValid;
  };

  const addSetsToCartFromButton = async () => {
    setUpdateVariantsInBulk(false);
    if (!isSetValid()) {
      const itemVariant = items.find(
        (item: any) => hasProductVariants(item.product) && !item.selectedGoodId,
      );

      const bulkVariantProductIds = getBulkVariantProductsIds(
        itemVariant.product,
        items.map((i) => i.product),
      );

      setVariantModalOpen({
        bulkVariantProductIds: bulkVariantProductIds,
        product: itemVariant.product,
      });
      setVariantModalError(false);
      return;
    }
    setLoading(true);
    const someSetsHasDeliveryToSchoolActivated = await props.dispatch(
      getSomeSetsHasDeliveryToSchoolActivated(),
    );
    if (
      isCartCointainsSkvelkoSet(props.cart) &&
      !getCartSkvelkoSetSchoolIds(props.cart).includes(
        props.skvelkoSet.clubUserId,
      ) &&
      (props.skvelkoSet.isDeliveryToSchoolActivated ||
        someSetsHasDeliveryToSchoolActivated)
    ) {
      setConfirmationModalOpen(true);
    } else {
      try {
        await addSetsToCart();
      } catch (e: any) {
        if (e?.details?.name === 'MAX_ITEMS_EXCEEDED') {
          await props.dispatch(setAddToCartMaxItemExceededModalProduct(true));
        }
      } finally {
      }
    }
    setLoading(false);
  };

  const addSetsToCartFromModal = async () => {
    setLoading(true);
    try {
      await addSetsToCart();
    } catch (e: any) {
      if (e?.details?.name === 'MAX_ITEMS_EXCEEDED') {
        await props.dispatch(setAddToCartMaxItemExceededModalProduct(true));
      }
    } finally {
      setLoading(false);
      setConfirmationModalOpen(false);
    }
  };

  const addSetsToCart = async () => {
    setSuccessModalOpen(true);
    const groupId = Math.random().toString(36);
    const setId = props.currentSet.id;
    const classId = props.currentClassId ? props.currentClassId : undefined;
    await props.dispatch(
      addItemsToCart(
        items.map((i) => ({
          good_id: i.selectedGoodId
            ? i.selectedGoodId
            : i.product.main_good.good_id,
          product_id: i.product.main_good.product_id,
          count: i.count,
          skvelko_set_id: setId,
          skvelko_class_id: classId,
          item_group_id: groupId,
        })),
      ),
    );
  };

  const onCloseConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const onCloseWarningRemoveFromSetModal = () => {
    setWarningCountId(null);
  };

  const onCloseSuccessModal = () => {
    props.dispatch(push('/'));
  };

  const onGoToCartClick = () => {
    props.dispatch(push('/kosik'));
  };

  const onUniversalSetClick = (setId) => {
    scrollToAnchor('scroll-anchor');
    props.dispatch(setCurrentSet(null));
    props.dispatch(loadFilterFromLocation(setId));
  };

  const onCloseSetVariantModal = () => {
    setVariantModalOpen(null);
  };

  const onConfirmVariantModal = (productIds) => {
    if (variantModalOpenEvent) {
      onVariationChangeModal(
        productIds,
        variantModalOpenEvent.attribName,
        variantModalOpenEvent.attribValue,
      );
    }
    setVariantModalOpenEvent(null);

    onCloseSetVariantModal();
    setTimeout(() => {
      addSetsToCartFromButton();
    }, 100);
  };

  const onVariationChange = (index, e) => {
    try {
      const selectedIndex = e.target.options.selectedIndex;
      const attribName = e.target.options[selectedIndex].getAttribute(
        'data-key',
      );
      const attribValue = e.target.value;

      const attribObj = {};
      attribObj[attribName] = attribValue;

      const value = e.goodId || e.target.value;
      const copyItems = items.slice(0);

      copyItems[index].selectedGoodId = value ? Number(value) : null;
      copyItems[index].isError = false;
      copyItems[index].attribObj = attribObj;

      const attribCount = copyItems[index]?.product?.attribs?.length;
      const goods = copyItems[index]?.product.goods;

      let resGood;
      for (const good of goods) {
        if (good && good.web_attribs && good.web_attribs.length > 0) {
          let matchedAttribCount = 0;
          good.web_attribs.forEach((attrib) => {
            const attribName = attrib?.attrib_name;
            const attribValue = attrib?.values[0]?.attrib_value;

            if (attribObj[attribName] == attribValue) {
              matchedAttribCount += 1;
            }
          });
          if (matchedAttribCount == attribCount) {
            resGood = good;
            break;
          }
        }
      }

      if (resGood) {
        copyItems[index].selectedGoodId = resGood.good_id;
      }

      setItems(copyItems);
    } catch (e) {
      console.log(e);
    }
  };

  const description = __('Zoznamy pomôcok pre nový školský rok');
  const title = props.currentSchoolName
    ? `Zoznamy pomôcok pre ${props.currentSchoolName}`
    : undefined;

  return (
    <React.Fragment>
      {props.metaTags ? (
        <MetaTags tags={props.metaTags} />
      ) : title ? (
        <MetaTags
          tags={{
            title,
            og_title: title,
            description,
            og_description: description,
          }}
        />
      ) : (
        <MetaTags tags={{ title: 'Daffer.sk' }} />
      )}

      {confirmationModalOpen && (
        <ChooseModal
          title={__('Potvrdenie')}
          bodyText={
            'Ak vložite do košíka viac ako jeden zoznam z rôznych škôl - nebudete mať možnosť využiť DOPRAVU DO ŠKOLY ZDARMA (podmienkou je že to vaša škola povolila). Ak chcete využit DOPRAVU DO ŠKOLY ZDARMA dokončite objednavku pre jeden zoznam a další zoznam objednajte v samostatnej objednávke.'
          }
          button1Text={__('Nevložiť do košíka')}
          button2Text={__('Vložiť do košíka')}
          onButton1Click={onCloseConfirmationModal}
          onButton2Click={addSetsToCartFromModal}
          onCloseButtonClick={onCloseConfirmationModal}
          loading={loading}
        />
      )}

      {warningCountId !== null && props.warningRemoveFromSetText && (
        <ChooseModal
          title={props.warningRemoveFromSetText.name}
          bodyText={props.warningRemoveFromSetText.body}
          button1Text={__('Odstrániť')}
          button2Text={__('Ponechať')}
          onButton1Click={onItemChange.bind(null, warningCountId, 0, true)}
          onButton2Click={onCloseWarningRemoveFromSetModal}
          onCloseButtonClick={onCloseWarningRemoveFromSetModal}
        />
      )}

      {variantModalOpen && (
        <BaseModal
          title={__('Zvoľte variant')}
          body={
            <>
              <p>
                {__(
                  'Pre vloženie zoznamu do košíka, zvoľte prosím variant produktu ',
                )}
                {variantModalOpen.product.name ? (
                  <strong>{variantModalOpen.product.name}</strong>
                ) : (
                  ''
                )}
                :
              </p>
              <Row>
                <ProductVariantSelect
                  product={variantModalOpen.product}
                  isError={variantModalError}
                  onChange={(e) => {
                    if (e.target) {
                      const selectedIndex = e.target.options.selectedIndex;
                      const attribName = e.target.options[
                        selectedIndex
                      ].getAttribute('data-key');
                      const attribValue = e.target.value;

                      setVariantModalOpenEvent({ attribName, attribValue });
                    }
                  }}
                  attribsObject={
                    variantModalOpen.product.attribObj
                      ? variantModalOpen.product.attribObj
                      : {}
                  }
                />
              </Row>

              {variantModalOpen.bulkVariantProductIds &&
              variantModalOpen.bulkVariantProductIds.length ? (
                <ModalBulkCheckboxWrapper>
                  <CheckboxStyled
                    name="update_variants_in_bulk"
                    value="1"
                    id="update_variants_in_bulk"
                    onChange={() => {
                      setUpdateVariantsInBulk(!updateVariantsInBulk);
                    }}
                    checked={updateVariantsInBulk}
                  >
                    <></>
                  </CheckboxStyled>
                  <span>
                    {__('použiť tento variant aj pre zvyšné produkty')}
                  </span>
                </ModalBulkCheckboxWrapper>
              ) : (
                <></>
              )}
            </>
          }
          footer={
            <StyledFooterModal>
              <CloseButton
                className="primary lg"
                onClick={onConfirmVariantModal.bind(
                  null,
                  updateVariantsInBulk
                    ? variantModalOpen.bulkVariantProductIds
                    : [variantModalOpen.product.product_id],
                )}
              >
                {__('Potvrdiť')}
              </CloseButton>
            </StyledFooterModal>
          }
          onCloseButtonClick={onCloseSetVariantModal}
        />
      )}

      {successModalOpen && (
        <SetAddedToCartModal
          onCloseButtonClick={onCloseSuccessModal}
          onGoToCartClick={onGoToCartClick}
          loading={loading}
        />
      )}

      <Loader loading={loading}>
        <ContainerIndentStyled
          className={`base ${
            !props.skvelkoSet && props.clubUserId ? 'padding-bottom' : ''
          }`}
        >
          {props.alertSchoolText && !canShowSkvelkoSearch(props.user) ? (
            <Alert
              heading={props.alertSchoolText.name}
              type={'error'}
              className={'separator'}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: props.alertSchoolText.body,
                }}
              />
              <AlertLink to={'/logout'}>{__('Odhlásiť sa')}.</AlertLink>
            </Alert>
          ) : (
            <>
              <SkvelkoSearchBox />
              <ScrollAnchor className="scroll-anchor" />
              {!props.skvelkoSet && !props.clubUserId ? (
                <SetsWrapper id={ANCHOR_UNIVERSAL_SETS}>
                  <SetsHeading>
                    {__('Nenašli ste vašu školu v zozname? Máme riešenie!')}
                    <br />
                    <strong>
                      {__('Univerzálne zoznamy pomôcok pre žiakov')}
                    </strong>
                  </SetsHeading>
                  <SetPreviews
                    sets={props.universalSets}
                    onUniversalSetClick={onUniversalSetClick}
                  />
                  {props.universalSetsSpecial?.length ? (
                    <>
                      <SetsHeading>
                        <strong>
                          {__('Doplnkové sady a školské pomôcky')}
                        </strong>
                      </SetsHeading>
                      <ProductsSlider
                        products={props.universalSetsSpecial}
                        breakpoints={{
                          1023: {
                            slidesPerView: 5,
                          },
                          768: {
                            slidesPerView: 4,
                          },
                          577: {
                            slidesPerView: 3,
                          },
                        }}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </SetsWrapper>
              ) : (
                ''
              )}
              {props.skvelkoSet?.productsWithCount?.length ? (
                <SetsList
                  user={props.user}
                  dispatch={props.dispatch}
                  currentFilterTitle={props.currentFilterTitle}
                  cart={props.cart}
                  setPopupText={props.setPopupText}
                  onItemChange={onItemChange}
                  onVariationChange={onVariationChange}
                  addSetsToCartFromButton={addSetsToCartFromButton}
                  items={items}
                />
              ) : (
                ''
              )}

              <Loader loading={props.skvelkoSetsIsLoading} />
            </>
          )}
        </ContainerIndentStyled>
      </Loader>
    </React.Fragment>
  );
};

Sets.getInitialProps = async (props) => {
  const promises: any[] = [];

  promises.push(props.dispatch(loadGlobalMetaTags(SETS_UNIQID)));

  if (props.clubUserId) {
    promises.push(props.dispatch(selectSchool(props.clubUserId)));
    promises.push(props.dispatch(setShowPopupSetSelect(true)));
    promises.push(props.dispatch(setShowPopupSetSelect2(false)));
  }

  if (!props.universalSets || !props.universalSets.length) {
    promises.push(props.dispatch(loadUniversalSets()));
  }

  if (!props.universalSetsSpecial || !props.universalSetsSpecial.length) {
    promises.push(props.dispatch(loadUniversalSetsSpecial()));
  }

  if (!props.skvelkoCities || !props.skvelkoCities.length) {
    promises.push(props.dispatch(loadSkvelkoCities()));
  }

  if (!props.setPopupText) {
    promises.push(props.dispatch(loadSetPopupText()));
  }

  if (!props.warningRemoveFromSetText) {
    promises.push(props.dispatch(loadWarningRemoveFromSetText()));
  }

  await Promise.all(promises);

  const id = prop(props, 'params.id');
  props.dispatch(loadSkvelkoBottomText());
  props.dispatch(loadSkvelkoDescriptionText());
  if (id) {
    props.dispatch(setCurrentSet(null));
    props.dispatch(loadFilterFromLocation(id));
  }

  return props;
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    skvelkoIsLoading: prop(state.home, 'skvelkoLoader', true),
    skvelkoSetsIsLoading: prop(state.home, 'skvelkoSetsLoader', false),
    skvelkoCities: prop(state.home, 'skvelkoCities', []),
    skvelkoSchools: prop(state.home, 'skvelkoSchools', []),
    skvelkoSets: prop(state.home, 'skvelkoSets', []),
    skvelkoClasses: prop(state.home, 'skvelkoClasses', []),
    skvelkoSetsIsEmpty: state.home.skvelkoSets
      ? !state.home.skvelkoSets.length
      : false,
    skvelkoClassesIsEmpty: state.home.skvelkoClasses
      ? !state.home.skvelkoClasses.length
      : false,
    skvelkoSet: state.home.skvelkoSet,
    universalSets: prop(state.home, 'universalSets', []),
    universalSetsSpecial: prop(state.home, 'universalSetsSpecial.products', []),
    currentFilterTitle: state.home.currentFilterTitle,
    currentSchoolName: state.home.currentSchoolName,

    currentSet: state.home.currentSet,
    currentClassId: state.home.currentClassId,
    setPopupText: state.home.setPopupText,
    warningRemoveFromSetText: state.home.warningRemoveFromSetText,
    alertSchoolText: state.general.alertSchoolText,

    cart: state.cart.cart,
    metaTags: state.general.globalMetaTags?.[SETS_UNIQID],
    settings: state.general.settings,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Sets' })(Sets),
);
