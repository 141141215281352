import * as React from 'react';
import {
  formatPrice,
  getProductPrices,
  itemIsSkvelko,
} from '../../../../utilities';
import { __ } from 'react-i18n';
import { rem } from 'polished';
import styled from 'styled-components';

const getPriceColor = (props) => {
  if (props.sale) {
    return ({ theme }) => theme.color.primary;
  } else if (props.discount) {
    return ({ theme }) => theme.color.success;
  } else {
    return 'initial';
  }
};

const Price = styled.p`
  color: ${(props) =>
    props.priceAfterNoColor ? 'initial' : getPriceColor(props)};
  font-size: ${rem(14)};
  font-weight: ${(props) => (props.crossed ? 400 : 700)};
  text-decoration: ${(props) => (props.crossed ? 'line-through' : 'normal')};
  text-align: center;
  margin: ${rem(2)} 0;
`;

const PriceLabel = styled(Price)`
  text-transform: uppercase;
`;

interface ProductPriceProps {
  product: any;
  user: any;
  cart: any;
  priceAfterNoColor?: boolean;
  isOnDemand?: boolean;
}

const ProductPrice = (props: ProductPriceProps) => {
  const { product, user, cart, priceAfterNoColor, isOnDemand } = props;

  const {
    currencyId,
    price,
    retailPrice,
    oldPrice,
    discountPrice,
    isSale,
    isDiscount,
    isWarehouse,
  } = getProductPrices(product, product?.main_good, cart, user);

  const renderPriceBefore = () => {
    if (product.main_good.price_create_type === 1) {
      return (
        <Price crossed={true}>
          {formatPrice(product.main_good.price_without_vat_sale, currencyId)}
        </Price>
      );
    } else if (isSale) {
      if (oldPrice && !isNaN(oldPrice)) {
        return (
          <Price crossed={true}>{formatPrice(oldPrice, currencyId)}</Price>
        );
      }
    } else if (isDiscount) {
      if (price && !isNaN(price)) {
        return <Price crossed={true}>{formatPrice(price, currencyId)}</Price>;
      }
    } else if (isWarehouse) {
      if (retailPrice && !isNaN(retailPrice)) {
        return (
          <Price crossed={true}>{formatPrice(retailPrice, currencyId)}</Price>
        );
      }
    } else {
      return '';
    }

    return '';
  };

  const renderLabel = () => {
    if (product.main_good.price_create_type === 1) {
      return <PriceLabel discount={true}>{__('Zľava')}</PriceLabel>;
    } else if (isSale) {
      return (
        <PriceLabel sale={true}>
          {itemIsSkvelko(product) ? __('Dobrá cena') : __('Akcia')}
        </PriceLabel>
      );
    } else if (isDiscount || isWarehouse) {
      return <PriceLabel discount={true}>{__('Zľava')}</PriceLabel>;
    } else {
      return '';
    }
  };

  const renderPriceAfter = () => {
    if (product.main_good.price_create_type === 1) {
      return (
        <Price>
          {formatPrice(product.main_good.price_without_vat, currencyId)}
        </Price>
      );
    } else if (isSale) {
      if (price && !isNaN(price))
        return (
          <Price sale={true} priceAfterNoColor={priceAfterNoColor}>
            {formatPrice(price, currencyId)}
          </Price>
        );
    } else if (isDiscount) {
      if (discountPrice && !isNaN(discountPrice))
        return (
          <Price discount={true} priceAfterNoColor={priceAfterNoColor}>
            {formatPrice(discountPrice, currencyId)}
          </Price>
        );
    } else if (isWarehouse) {
      if (price && !isNaN(price))
        return (
          <Price discount={true} priceAfterNoColor={priceAfterNoColor}>
            {formatPrice(price, currencyId)}
          </Price>
        );
    } else {
      if (price && !isNaN(price))
        return (
          <Price priceAfterNoColor={priceAfterNoColor}>
            {formatPrice(price, currencyId)}
          </Price>
        );
    }

    return (
      <Price priceAfterNoColor={priceAfterNoColor}>
        {' '}
        <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />{' '}
      </Price>
    );
  };

  return (
    <>
      {renderPriceBefore()}
      {!isOnDemand ? renderLabel() : ''}
      {renderPriceAfter()}
    </>
  );
};
export default ProductPrice;
